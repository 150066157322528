import { Box, Skeleton, Stack } from "@mui/material"
import { boxborder } from "../Utils/colors"

export const Normalrectangleskeleton = () => {

    return (
        <div style={{ marginLeft: 2.5, marginRight: 2.5 }}>
            <Skeleton variant="rectangular" sx={{ height: 50, width: "100%", marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 20, width: "100%", marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 10, width: 80, marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 10, width: 100, borderRadius: 1, marginBottom: 2 }} />
        </div>
    )
}

export const Assessmentskeleton = () => {

    return (
        <Box height={190} width={"23%"} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', borderRadius: 2, cursor: "pointer" }}>
            <Stack flexDirection={'row'} justifyContent={"space-between"} mb={1}>
                <Skeleton variant="rectangular" sx={{ height: 30, width: "70%" }} />
                <Skeleton variant="rectangular" sx={{ height: 30, width: 30, borderRadius: "100%" }} />
            </Stack>
            <Skeleton variant="rectangular" sx={{ height: 20, width: "90%", marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 15, width: "40%", marginBottom: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 5, width: "100%" }} />
            <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                <Box width={"30%"}>
                    <Skeleton variant="rectangular" sx={{ height: 15, width: "100%" }} />
                    <Skeleton variant="rectangular" sx={{ height: 15, width: "40%", marginTop: 1 }} />
                </Box>
                <Box width={"30%"}>
                    <Skeleton variant="rectangular" sx={{ height: 15, width: "100%" }} />
                    <Skeleton variant="rectangular" sx={{ height: 15, width: "40%", marginTop: 1 }} />
                </Box>
            </Stack>
        </Box>
    )
}

export const CandidateDashboardSkeleton = () => {

    return (
        <Box width={"100%"}>
            <Skeleton variant="rectangular" sx={{ height: 50, width: "100%", mb: 2, borderRadius: 3 }} />
            <Stack flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                <Skeleton variant="rectangular" sx={{ height: "100vh", width: "30%" }} />
                <Box width={"68%"}>
                    <Skeleton variant="rectangular" sx={{ height: 40, width: "100%", mb: 2, borderRadius: 1 }} />
                    <Stack flexDirection={"row"} justifyContent={"space-between"} mb={2} width={"100%"}>
                        <Skeleton variant="rectangular" sx={{ height: 80, width: "30%", borderRadius: 1 }} />
                        <Skeleton variant="rectangular" sx={{ height: 80, width: "30%", borderRadius: 1 }} />
                        <Skeleton variant="rectangular" sx={{ height: 80, width: "30%", borderRadius: 1 }} />
                    </Stack>
                    <Skeleton variant="rectangular" sx={{ height: "80vh", width: "100%", mb: 2, borderRadius: 1 }} />
                </Box>
            </Stack>

        </Box>
    )
}


