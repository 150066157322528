import { boxborder, primarycolor, semiprimarycolor } from "./colors"
import { TextField, styled } from "@mui/material";

const muistyles = {
    textinputstyles: {
        maxHeight: 20,
        marginRight: 5,
        width: "100%",
        marginBottom: 5,
        fontSize: 14,
        fontFamily: "lightfont"
    },
    floatingmodal: {
        backgroundColor: "white",
        marginTop: 5,
        borderRadius: 2,
        padding: 1,
        position: "relative"
    },
    floatingcontainer: {
        backgroundColor: "white",
        marginTop: 5,
        borderRadius: 2,
        padding: 2,
        position: "relative",
    },
    tableheadstyle: {
        backgroundColor: primarycolor,
        color: "white"
    },
    tableheadrowstyle: {
        color: "white",
        fontWeight: "bold",
        whiteSpace: "nowrap"
    },
    tableresultsrowstyle: {
        color: primarycolor,
        fontWeight: "bolder"
    },
    tableheadrowstylenames: {
        color: "white",
        fontWeight: "bold",
        width: 200
    },
    ovalbutton: {
        borderRadius: 10,
        height: 40,
        fontFamily: "lightfont"
    },
    skillsbox: {
        border: '1px solid silver',
        borderRadius: 3,
        padding: 1,
        mt: 2
    },
    frameworksbox: {
        backgroundColor: '#f5f5ff',
        padding: { xs: 1, lg: 4 },
        mt: 3,
        borderRadius: 3
    },
    onboardingleft: {
        height: "100%",
        background: `linear-gradient(to left, ${primarycolor}, #57a2df)`,
        padding: 6,
        display: { xs: "none", lg: "block" }
    },
    onboardingright: {
        height: "100%",
        background: `linear-gradient(to right, ${primarycolor}, #57a2df)`,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        display: { xs: "none", lg: "flex", },
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    onboardingleft_box1: { xs: "100%", lg: "60%" },
    onboardingleft_box2: { xs: "98%", lg: "90%" },
    twolinetextlimit: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    whitebutton: {
        height: { xs: 25, lg: 35 },
        fontSize: { xs: 14, lg: 15 },
        textTransform: "none",
        whiteSpace: "nowrap",
        backgroundColor: 'white',
        color: 'black', 
        '&:hover': {
            backgroundColor: 'white',
            color: 'black'
        },
        borderRadius: { xs: 1, lg: 3 }
    },
    stackstyle: {
        backgroundColor: "#fafafa",
        border: boxborder,
        borderRadius: 2,
        padding: 1
    },
    headtitle: {
        width: "13%",
        fontSize: 13,
        fontFamily: "boldfont"
    },
    contentheadtitle: {
        width: "13%",
        fontSize: 13,
        whiteSpace: "nowrap"
    },
    contentstackstyle: {
        justifyContent: "space-between",
        backgroundColor: "#fafafa",
        borderBottom: boxborder,
        padding: 1
    },
    noscrollbar: {
        overflowY: "scroll",
        '&::-webkit-scrollbar': {
            display: 'none' // Hide the scroll bar in WebKit browsers
        },
        msOverflowStyle: 'none',  // Hide the scroll bar in Internet Explorer and Edge
        scrollbarWidth: 'none'
    }
}


// mui text search bar input
export const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        maxHeight: 45,
        borderRadius: 10,
        "& fieldset": {
            border: "none",
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        "&:hover fieldset": {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        "&.Mui-focused fieldset": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
    },
}));



export default muistyles