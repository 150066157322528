import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { BookmarkAdded, BookmarkBorder, FiberManualRecord, Notifications, Search } from '@mui/icons-material';
import { primarycolor, white } from '../Utils/colors';
import { useNavigate } from 'react-router-dom';
import { Badge, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { imgCheck } from '../Utils/functions';
import { dummymanimage } from '../Utils/dummy';
import Notificationsmodal from '../Dashboards/Candidate/Account/notifications';
import { user_pic_placeholder } from '../Utils/defaults';



export default function AccountMenu({ logout, counter }) {
    const { user } = useSelector((state) => state.user);
    const { saved_candidates } = useSelector((state) => state.saved_candidates);

    const [profileurl, setprofileurl] = React.useState(null);
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState('')
    const letter = user?.name?.substring(0, 1);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        imgCheck(user?.profile_picture_url, user_pic_placeholder).then((src) => setprofileurl(src));
    }, [user]);


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const saved_message = saved_candidates?.length > 0 ? `You have ${saved_candidates.length} saved Candidates` : 'You have no saved candidates'

    const avatarstyle = { width: 32, height: 32, backgroundColor: 'black' }
    return (
        <React.Fragment>
            <Stack flexDirection={"row"} alignItems={'center'}>
                {user?.account_type === 'CLIENT' &&
                    <IconButton
                        title={saved_message}
                        color={saved_candidates?.length > 0 ? "primary" : "secondary"}
                        onClick={() => navigate("/clientaccount/saved-candidates")}
                    >
                        {saved_candidates?.length > 0 &&
                            <Typography
                                height={20}
                                width={20}
                                borderRadius={"100%"}
                                sx={{
                                    position: "absolute", right: -1, top: -2, zIndex: 5,
                                    backgroundColor: "black"
                                }}
                                fontSize='small' fontFamily={"boldfont"} color={'white'} >
                                {saved_candidates.length}
                            </Typography>}
                        <BookmarkAdded />
                    </IconButton>
                }
                &nbsp;&nbsp;
                <Box mr={1}>
                    <Notificationsmodal />
                </Box>
                <Tooltip title="Account settings"
                    onClick={handleClick}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Stack flexDirection={'row'} alignItems={"center"}>
                        <IconButton size="small" >
                            {profileurl ? <img className="usertopheaderimg" src={profileurl} />
                                : <Avatar sx={avatarstyle}>{letter}</Avatar>
                            }
                        </IconButton>
                        <Box display={{ lg: "block", sm: 'none', xs: 'none' }} >
                            <Typography fontSize={13} fontFamily={'boldfont'} color={'black'}>{user?.name}</Typography>
                            <Typography fontSize={12} color={'var(--dimtextcolor)'}>{user?.role}</Typography>
                        </Box>
                    </Stack>

                </Tooltip>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem> */}
                <MenuItem onClick={() => {
                    handleClose()
                    user?.account_type === 'CLIENT' ? navigate("/clientaccount/client-account-settings") : navigate("/candidateaccount/candidate-account-settings")
                }}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        {profileurl ?
                            <img className="usertopheaderimg" src={profileurl} /> :
                            <Avatar sx={avatarstyle}>{letter}</Avatar>
                        } &nbsp;
                        <Typography>My account</Typography>
                    </Stack>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {
                    handleClose()
                    user?.account_type === 'CLIENT' ? navigate("/clientaccount/client-account-settings") : navigate("/candidateaccount/candidate-account-settings")
                }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    logout()
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}