import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, CircularProgress, InputAdornment, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { East, KeyboardBackspace, LocationOnOutlined } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes, skilllevels } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customreactselectinput, Customsectiontextinput, Customselectinput } from "../../../../Components/textinputs.";
import countryList from 'react-select-country-list'
import { removeNewassessment, setNewassessment } from "../../../../../store/newassessment";
import useAuthAxios from "../../../../../hooks/useAuthAxios";
import { Erroralert } from "../../../../Components/popups";
import { Createassessmentcandidateroleurl } from "../../../../Utils/urls";


const Asssessmentbuilder1 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('')

    const dispatch = useDispatch()
    const countryoptions = useMemo(() => countryList().getData(), [])

    const [role, setrole] = useState(newassessment?.role || '');
    const [country, setcountry] = useState(newassessment?.country || '');
    const [city, setcity] = useState(newassessment?.city || '');
    const [level, setlevel] = useState(newassessment?.level || '');
    const [commitmenttype, setcommitmenttype] = useState(newassessment?.commitmenttype || '');




    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);


    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Createassessmentcandidateroleurl, {
            position: role,
            location: `${city}|${country}`,
            work_place_type: commitmenttype,
            skill_level: level
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                gotonext(response.data?.data?.role?.id)
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }


    const gotonext = (role_id) => {
        let updatedassessment = { role, country, city, commitmenttype, level, role_id }
        if (newassessment) {
            updatedassessment = { ...newassessment, ...updatedassessment }
        }

        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-2")
    }



    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Role Details</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={0} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={{ xs: 3, lg: 6 }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                    <Customsectiontextinput
                        fieldname={"Role/Position"}
                        hint={'i.e React Developer'}
                        value={role}
                        required={true}
                        onChange={setrole}
                        width={"48%"}
                    />
                    <Customselectinput
                        options={skilllevels.map((level) => level.label)}
                        fieldname={"Skill Level"}
                        hint={'i.e Expert'}
                        value={level}
                        required={true}
                        onChange={setlevel}
                        width={"48%"}
                    />
                    <Customreactselectinput
                        fieldname={"Country"}
                        hint={'i.e Kenya'}
                        onChange={(e) => setcountry(e.label)}
                        width={"48%"}
                        options={countryoptions}
                    />
                    <Customsectiontextinput
                        fieldname={'City'}
                        hint={'Nairobi'}
                        placeholder={'city'}
                        width={"48%"}
                        value={city}
                        onChange={setcity}
                        inputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <LocationOnOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Customselectinput
                        fieldname={"Commitment Type"}
                        hint={'i.e Full-time'}
                        value={commitmenttype}
                        onChange={setcommitmenttype}
                        width={"48%"}
                        options={commitmenttypes}
                    />
                </Stack>
                <Stack flexDirection={"row"} justifyContent={"right"}>
                    <Button onClick={Postdata} disabled={!role || !level} sx={buttonstyle} endIcon={<East />} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Asssessmentbuilder1