import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { primarycolor, secondarycolor } from './pages/Utils/colors';
import Loadingspinner from './pages/Components/loading';
import { AccountCircle, DataObject, ExploreOutlined, HelpOutline, HistoryEduOutlined, Home, HomeOutlined, MenuOpen, MenuOutlined, NotificationsActive, SchoolOutlined, WorkspacePremium } from '@mui/icons-material';
import Popupdialog from './pages/Components/popupcomponent';
import { AppBar, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import useAuthAxios from './hooks/useAuthAxios';
import { useDispatch, useSelector } from 'react-redux';
import { Deleteaccounturl, Logoutuserurl } from './pages/Utils/urls';
import { logoutUser } from './store/user';
import AccountMenu from './pages/Components/accountmenu';
import Receivefeedback from './pages/Dashboards/Candidate/Feedback/collectfeedback';
import Clienthome from './pages/Dashboards/Client/clienthome';
import MyclientaccountSettings from './pages/Dashboards/Client/Account/myaccount';
import Clientassessments from './pages/Dashboards/Client/Assessments/clientassessments';
import Asssessmentbuilder1 from './pages/Dashboards/Client/Assessments/Builder/builder1';
import Asssessmentbuilder2 from './pages/Dashboards/Client/Assessments/Builder/builder2';
import Assessmentbuilder3 from './pages/Dashboards/Client/Assessments/Builder/builder3';
import Assessmentbuilder4 from './pages/Dashboards/Client/Assessments/Builder/builder4';
import Assessmentbuilder5 from './pages/Dashboards/Client/Assessments/Builder/builder5';
import Assessmentbuilder6 from './pages/Dashboards/Client/Assessments/Builder/builder6';
import Savedcandidates from './pages/Dashboards/Client/Candidates/saved_candidates';
import Discovercandidates from './pages/Dashboards/Client/Candidates/discovercandidates';
import Viewassessment from './pages/Dashboards/Client/Assessments/viewassessment';
import Reviewassessment from './pages/Dashboards/Client/Assessments/reviewassessment';



const drawerWidth = isMobile ? "70%" : "18%";
const iconsize = 'medium'
const outlinefontsize = 15

const iconstyle = {
    color: "white"
}
const subiconstyle = {
    color: primarycolor
}
const mainlinksstyle = {
    color: "white",
    fontSize: outlinefontsize
}
const mainlinksstyleactive = {
    whiteSpace: "nowrap",
    color: primarycolor,
    fontSize: outlinefontsize,
    fontFamily: 'boldfont'
}


const linksarray = [
    {
        name: "Dashboard",
        links: [
            "/clientaccount/clienthome"
        ],
        icon: <HomeOutlined fontSize={iconsize} />
    },
    {
        name: "Assessments",
        links: [
            "/clientaccount/clientassessments",
            "/clientaccount/client-view-assessment",
            "/clientaccount/assessment-builder-1",
            "/clientaccount/assessment-builder-2",
            "/clientaccount/assessment-builder-3",
            "/clientaccount/assessment-builder-4",
            "/clientaccount/assessment-builder-5",
            "/clientaccount/assessment-builder-6",
            "/clientaccount/review-assessment"
        ],
        icon: <DataObject fontSize={iconsize} />
    },
    {
        name: "Discover",
        links: [
            "/clientaccount/discover-candidates",
            "/clientaccount/saved-candidates"
        ],
        icon: <ExploreOutlined fontSize={iconsize} />
    },
    {
        name: "Account",
        links: [
            "/clientaccount/client-account-settings"
        ],
        icon: <AccountCircle fontSize={iconsize} />
    },
    {
        name: "Feedback",
        links: ["/clientaccount/feeback"],
        icon: <HelpOutline fontSize={iconsize} />
    }
]


const Clientnavigation = () => {
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();


    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');


    const [refresh, setrefresh] = useState(false);
    const [loading, setloading] = useState(false);

    const [messagecounter, setmessagecounter] = useState(4);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showbackonline, setshowbackonline] = useState(false);
    const [showdrawer, setshowdrawer] = useState(true);
    const pathname = location?.pathname


    //modals
    const [logoutdialog, setlogoutdialog] = useState(false);
    const [showcloseaccount, setshowcloseaccount] = useState(false);




    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setshowbackonline(true)
            setTimeout(() => { setshowbackonline(false) }, 3000);
        };
        const handleOffline = () => {
            setIsOnline(false);
        };
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    //logout from site
    const onlogout = () => {
        setlogoutdialog(false)
        setshowcloseaccount(false)
        setloading(true)
        axiosInstance.post(Logoutuserurl).then((response) => {
            setloading(false)
            dispatch(logoutUser())
            navigate("/login", { replace: true })
        }).catch((error) => {
            setloading(false)
            seterror('unable to logout from your current session')
        })
    }


    //close account
    const closeaccount = () => {
        setloading(true);
        axiosInstance.post(Deleteaccounturl)
            .then(function (response) {
                setloading(false);
                if (!response.data.error) {
                    setsuccess("Account closed successfully");
                    setTimeout(() => {
                        setsuccess('')
                        dispatch(logoutUser())
                        navigate("/", { replace: true })
                    }, 5000);
                } else {
                    seterror(response.data.message);
                }
            }).catch(function (error) {
                setloading(false);
                seterror("There was an error. Please try again later.");
                console.log(error);
            });
    }



    return (
        <Box width={"100vw"}>
            <Box sx={{ display: 'flex' }}>
                {loading && <Loadingspinner />}
                {logoutdialog &&
                    <Popupdialog
                        toptext={"Confirm exit!"}
                        question={"Are you sure you want to logout from this platform?"}
                        successtext={"Logout"}
                        oncancel={() => setlogoutdialog(false)}
                        onsuccess={() => onlogout()}
                    />}
                {showcloseaccount &&
                    <Popupdialog
                        toptext={"Confirm account closure!"}
                        question={" Are you sure you want to close your account?"}
                        successtext={"Close account"}
                        oncancel={() => setshowcloseaccount(false)}
                        onsuccess={() => closeaccount()}
                    />}
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: {
                            lg: `calc(100% - ${drawerWidth})`,
                            md: '100%', sm: "100%", xs: "100%"
                        },
                        ml: {
                            lg: `${drawerWidth}`, md: 0, sm: 0, xs: 0
                        },
                        backgroundColor: 'white'
                    }}
                >
                    <Toolbar >
                        <Stack flex={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack flexDirection={"row"} alignItems={"center"} color={'black'} fontSize={14}>
                                <Box mr={1} sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' } }} onClick={() => setshowdrawer(true)}>
                                    <MenuOutlined />
                                </Box>
                                {/* {user?.currenticon} */}
                                <Typography fontFamily={'boldfont'} noWrap >{user?.currentpage || 'Dashboard'}</Typography>
                            </Stack>
                            <AccountMenu user={user} logout={() => setlogoutdialog(true)} counter={messagecounter} />
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: 'black',
                            padding: 1
                        },
                    }}
                    variant={isMobile ? "temporary" : "permanent"}
                    anchor="left"
                    color='primary'
                    open={showdrawer}
                    onClose={() => setshowdrawer(false)}
                >
                    {!isOnline && <div className='offlinediv'>No internet Connection</div>}
                    {showbackonline && <div className='onlinediv'>Back Online</div>}
                    <Box>
                        <img src={require('./assets/images/Logo/iconblack.png')} style={{ height: 60, borderRadius: 5 }} />
                    </Box>
                    <Divider />
                    <List>
                        {linksarray.map((val, key) => {
                            const isactive = val.links?.includes(pathname)
                            return (
                                <ListItem key={key} disablePadding onClick={() => {
                                    navigate(val.links[0])
                                    setshowdrawer(false)
                                }}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: isactive ? subiconstyle : iconstyle }}>  {val.icon} </ListItemIcon>
                                        <ListItemText primary={val.name} primaryTypographyProps={isactive ? mainlinksstyleactive : mainlinksstyle} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}

                    </List>
                    <Divider />
                    <List>
                        <div style={{ position: "fixed", bottom: 10 }}>
                            <ListItem disablePadding onClick={() => setlogoutdialog(true)}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PowerSettingsNewIcon size={iconsize} sx={iconstyle} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Signout"} primaryTypographyProps={mainlinksstyle} />
                                </ListItemButton>
                            </ListItem>
                        </div>

                    </List>
                </Drawer>
                <Box
                    component="main"
                    width={isMobile ? '100%' : '82%'}
                    sx={{
                        p: isMobile ? 0 : 3,
                        maxWidth: isMobile ? '100%' : '82%',
                        backgroundColor: '#f9f9f9',
                        pt: 10
                    }}
                >
                    <Routes>
                        <Route path="/*" exact element={<Navigate replace to="/clientaccount/clienthome" />} />
                        <Route path="/clienthome" element={<Clienthome />} exact />

                        {/**Asessments */}
                        <Route path="/clientassessments" element={<Clientassessments />} exact />
                        <Route path="/client-view-assessment" element={<Viewassessment />} exact />
                        <Route path="/review-assessment" element={<Reviewassessment />} exact />



                        <Route path="/discover-candidates" element={<Discovercandidates />} exact />
                        {/**Asessment Builder */}
                        <Route path="/assessment-builder-1" element={<Asssessmentbuilder1 />} exact />
                        <Route path="/assessment-builder-2" element={<Asssessmentbuilder2 />} exact />
                        <Route path="/assessment-builder-3" element={<Assessmentbuilder3 />} exact />
                        <Route path="/assessment-builder-4" element={<Assessmentbuilder4 />} exact />
                        <Route path="/assessment-builder-5" element={<Assessmentbuilder5 />} exact />
                        <Route path="/assessment-builder-6" element={<Assessmentbuilder6 />} exact />

                        {/**Feedback */}
                        <Route path="/feeback" element={<Receivefeedback />} exact />


                        {/**Account settings */}
                        <Route path="/client-account-settings" element={<MyclientaccountSettings ondeleteaccount={() => setshowcloseaccount(true)} />} exact />
                        <Route path="/saved-candidates" element={<Savedcandidates />} exact />

                    </Routes>
                </Box>
            </Box>
        </Box>
    );
}



export default Clientnavigation;
