import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Add, Cancel, Clear, DriveFileRenameOutline, East, InfoOutlined, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes, Questiontypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customcheckbox, Customsectiontextinput, Customselectinput } from "../../../../Components/textinputs.";
import { v4 as uuidv4 } from 'uuid';
import { Erroralert } from "../../../../Components/popups";
import { navigateto } from "../../../../Utils/functions";
import { setNewassessment } from "../../../../../store/newassessment";
import useAuthAxios from "../../../../../hooks/useAuthAxios";
import { Fetchassesmentsurl, Linkroleassessmenturl, Postassessmentquestionsurl } from "../../../../Utils/urls";
import Assessmentbuilder4 from "./builder4";



const Assessmentbuilder3 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const dispatch = useDispatch()
    const [error, seterror] = useState('');
    const [loading, setloading] = useState(false);

    const defaultoption = { option_text: "", is_correct: false };
    const defaultansweroptions = Array.from({ length: 3 }, () => ({ ...defaultoption }));

    const [showquestions, setshowquestions] = useState(false);

    //assessment
    const [title, settitle] = useState(newassessment?.title);
    const [description, setdescription] = useState(newassessment?.description);
    const [type, settype] = useState('');
    const [instructions, setinstructions] = useState(newassessment?.instructions);
    const [duration, setduration] = useState(newassessment?.duration || 60);


    const [customquestions, setcustomquestions] = useState(newassessment?.customquestions || []);

    const [currentQuestionId, setcurrentQuestionId] = useState('');
    const [question_text, setquestion_text] = useState('');
    const [answeroptions, setansweroptions] = useState(defaultansweroptions);

    const inputRefs = useRef([]);

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
        if (newassessment?.custom_assessment_id) {
            setshowquestions(true)
        }
    }, []);

    ///auto add answer options
    useEffect(() => {
        const arraylength = answeroptions.length
        const checkfilledoptions = answeroptions.filter((obj) => obj.option_text);
        if (checkfilledoptions.length + 1 >= arraylength) {
            setansweroptions([...answeroptions, defaultoption])
        }
    }, [answeroptions]);


    const postassessment = () => {
        setloading(true)
        axiosInstance.post(Fetchassesmentsurl, {
            title, description, duration, type, level: newassessment?.level, instructions: JSON.stringify(newassessment.instructions)
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const customid = response.data.data.assessment_details.id
                const updatedassessment = { ...newassessment, custom_assessment_id: customid, title, description, duration }
                Linkassessment(customid)
                dispatch(setNewassessment(updatedassessment))
                setshowquestions(true)
            } else {
                seterror('Unable to create assesment')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }

    //Link assesments with role
    const Linkassessment = (assessment_id) => {
        const assessmnents_ids = assessment_id ? [...(newassessment.selectedasessments?.map((val) => val.id)), assessment_id] : [...(newassessment.selectedasessments?.map((val) => val.id))]

        axiosInstance.post(Linkroleassessmenturl, {
            assessment_id: assessmnents_ids,
            role_id: newassessment?.role_id
        })
    }

    //post assessment questions
    const postassessment_questions = () => {
        setloading(true)
        axiosInstance.post(Postassessmentquestionsurl, {
            questions: customquestions,
            assessment_id: newassessment?.custom_assessment_id
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                gotonext()
            } else {
                seterror('Unable to post custom questions')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }

    //handle question adding to custom questions
    const handlequestionadd = () => {
        const existingQuestion = customquestions.find((question) => question.id === currentQuestionId); // Use unique id to find the question

        let filledoptions = answeroptions.filter((obj) => obj.option_text);

        if (type === Questiontypes[0] && filledoptions.length < 1) {
            seterror('No answers provided');
            return;
        }

        if (type === Questiontypes[0] && filledoptions.findIndex((obj) => obj?.is_correct) < 0) {
            seterror('No correct answer set');
            return;
        }


        const questionobject = {
            id: existingQuestion?.id || uuidv4(),  // Reuse existing id if updating, or generate new one
            question_text,
            question_type: type,
            options: filledoptions,
        };

        if (existingQuestion) {
            // If question exists, update it
            const updatedQuestions = customquestions.map((question) =>
                question.id === currentQuestionId ? questionobject : question
            );
            setcustomquestions(updatedQuestions);
        } else {
            // If question doesn't exist, add it to the array
            setcustomquestions([...customquestions, questionobject]);
        }

        // Clear fields and reset state
        navigateto("top");
        setansweroptions([]);
        setquestion_text('');
        setcurrentQuestionId(null);  // Reset the current question ID for new additions
        seterror(null);
    };



    //remove question
    const removequestion = (key) => {
        setcustomquestions(customquestions.filter((obj, questionkey) => questionkey !== key))
    }


    //move through the form
    const optiontextkeydown = (event, index) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index < answeroptions.length - 1) {
                // If Enter key is pressed and not the last option
                inputRefs.current[index + 1].focus(); // Focus on the next option question input
            }
        } else if (event.key === "Backspace" && index > 0 && answeroptions[index].option_text === "") {
            event.preventDefault();
            inputRefs.current[index - 1].focus(); // Focus on the previous option input
        }
    }

    //on change question text 
    const onchangequestiontext = (text, key, event) => {
        const clonedoptions = [...answeroptions]
        clonedoptions[key].option_text = text
        setansweroptions(clonedoptions)
    }

    //onchange correct answer
    const onchangecorrectanswer = (is_correct, key) => {
        const clonedoptions = [...answeroptions]
        clonedoptions[key].is_correct = !is_correct
        setansweroptions(clonedoptions)
    }

    //remove option
    const removeoption = (optionkey) => {
        setansweroptions(answeroptions.filter((obj, key) => key !== optionkey))
    }

    //setquestion in play
    const setquestioninplay = (question) => {
        setquestion_text(question.question_text)
        setansweroptions(question?.options)
        setcurrentQuestionId(question?.id)
    }



    const gotonext = (no_custom) => {
        if (no_custom) {//if there's no custom questions
            Linkassessment()
        }
        const updatedassessment = { ...newassessment, customquestions }
        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-5")
    }


    const desctext = `You can create a custom assessment or proceed without one if you had selected a system assessment in the previous step`


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Stack flexDirection={"row"} alignItems={"center"}>
                    <Typography mr={1} fontFamily={'boldfont'}>Custom Assessment</Typography>
                    <InfoOutlined sx={{ cursor: "pointer" }} fontSize="small" titleAccess={desctext} />
                </Stack>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={2} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div id="top" />
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, }}>
                {!showquestions ?
                    <Box>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Customsectiontextinput
                                fieldname={"Title"}
                                hint={'i.e IQ Test'}
                                value={title}
                                onChange={settitle}
                                width={"48%"}
                            />
                            <Customselectinput
                                options={Questiontypes}
                                onChange={settype}
                                value={type}
                                width={"48%"}
                                fieldname={'Assessment type'}
                            />
                        </Stack>
                        <br />
                        <Customsectiontextinput
                            fieldname={"Description"}
                            placeholder={'Type assessment description'}
                            value={description}
                            multiline={true}
                            required={true}
                            rows={3}
                            onChange={setdescription}
                            width={"100%"}
                        />
                        <Customsectiontextinput
                            fieldname={"Duration"}
                            hint={'in Minutes'}
                            value={duration}
                            onChange={setduration}
                            width={"100%"}
                        />
                        <Assessmentbuilder4 />
                        <br />
                    </Box> :
                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                        <Box width={"48%"} component={'form'} onSubmit={(e) => {
                            e.preventDefault()
                            handlequestionadd()
                        }}>
                            <Customselectinput
                                fieldname={"Question Type"}
                                hint={'i.e MultiChoices'}
                                options={Questiontypes}
                                value={type}
                                onChange={() => { }}
                                width={"100%"}
                            /><br />
                            <Customsectiontextinput
                                fieldname={"Question"}
                                hint={'i.e Define hooks in react'}
                                value={question_text}
                                multiline={true}
                                required={true}
                                rows={3}
                                onChange={setquestion_text}
                                width={"100%"}
                            />
                            {type === Questiontypes[0] &&
                                <Box>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography>Answer Options</Typography>
                                    </Stack>
                                    <Box>
                                        {answeroptions.map((answer, key) => {

                                            return (
                                                <Stack key={key} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Customsectiontextinput
                                                        inputRef={(ref) => (inputRefs.current[key] = ref)}
                                                        fieldname={"Option " + (key + 1)}
                                                        handleKeyDown={(e) => optiontextkeydown(e, key)}
                                                        value={answer.option_text}
                                                        onChange={(text) => onchangequestiontext(text, key)}
                                                        width={"80%"}
                                                    />
                                                    <Customcheckbox title="check as the correct option" onChange={() => onchangecorrectanswer(answer.is_correct, key)} value={answer.is_correct} />
                                                    <Clear sx={{ cursor: "pointer" }} onClick={() => removeoption(key)} color="error" />
                                                </Stack>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            }
                            <Button type="submit" variant="contained" sx={{ textTransform: "none", mb: 2 }}>Add Question</Button>
                        </Box>
                        <Box width={"48%"} sx={{ height: "60vh", overflowY: "scroll" }}>
                            {customquestions.length > 0 &&
                                <Box>
                                    <Typography fontFamily={"boldfont"}>Questions ({customquestions.length})</Typography>
                                    {customquestions.map((question, key) => {

                                        return (
                                            <Box position={"relative"} mb={2} padding={2} sx={{ border: boxborder, borderRadius: 3 }} key={key}>
                                                <Stack flexDirection={"row"} position={"absolute"} right={2} top={1}>
                                                    <DriveFileRenameOutline color="primary" onClick={() => setquestioninplay(question)} sx={{ cursor: "pointer" }} />&nbsp;
                                                    <Cancel titleAccess="click to remove" onClick={() => removequestion(key)} sx={{ cursor: "pointer" }} color="error" />
                                                </Stack>

                                                <Stack flexDirection={"row"}>
                                                    <Typography mr={1} fontFamily={"boldfont"}>{key + 1})</Typography>
                                                    <Box width={"92%"}>
                                                        <Typography fontFamily={"boldfont"}>{question.question_text}</Typography>
                                                        <Typography >{question.question_type}</Typography>
                                                    </Box>
                                                </Stack>

                                            </Box>
                                        )
                                    })}
                                </Box>
                            }
                        </Box>
                    </Stack>}
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Stack flexDirection={"row"}>
                        {newassessment?.selectedasessments?.length > 0 && <Button onClick={() => gotonext(true)} sx={{ mr: 1, borderRadius: 5 }} variant="outlined">Proceed without Custom Questions</Button>}
                        <Button
                            onClick={showquestions ? postassessment_questions : postassessment}
                            disabled={showquestions ? customquestions?.length < 1 : !title || !description || !duration || !newassessment?.instructions}
                            sx={buttonstyle}
                            endIcon={<East />}
                            variant="contained"
                        >
                            {loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}
                        </Button>
                    </Stack>
                </Stack>

            </Box>

        </Box >
    )
}

export default Assessmentbuilder3