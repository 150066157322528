import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { Clear } from "@mui/icons-material";
import { Customsectiontextinput } from "../../../../Components/textinputs.";
import { setNewassessment } from "../../../../../store/newassessment";




const Assessmentbuilder4 = () => {
    const dispatch = useDispatch()
    const { newassessment } = useSelector((state) => state.newassessment);
    const defaultinstruction = { instruction: "" };
    const defaultintructions = Array.from({ length: 3 }, () => ({ ...defaultinstruction }));

    const [instructions, setinstructions] = useState(newassessment?.instructions || defaultintructions);

    const inputRefs = useRef([]);

    ///auto add instructions
    useEffect(() => {
        const arraylength = instructions.length
        const checkfilledoptions = instructions.filter((obj) => obj.instruction);
        if (checkfilledoptions.length + 1 >= arraylength) {
            setinstructions([...instructions, defaultinstruction])
        }
    }, [instructions]);

    useEffect(() => {
        const interval = setInterval(() => {
            // Dispatch the updated instructions while keeping the rest of the newassessment object unchanged
            dispatch(setNewassessment({
                ...newassessment,  // Spread the existing newassessment object to retain other properties
                instructions: instructions.filter((obj) => obj?.instruction)      // Only update the instructions field
            }));
        }, 5000); // Update every 5 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [instructions]);



    //move through the form
    const instructiontextkeydown = (event, index) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index < instructions.length - 1) {
                // If Enter key is pressed and not the last instruction
                inputRefs.current[index + 1].focus(); // Focus on the next instruction input
            }
        } else if (event.key === "Backspace" && index > 0 && instructions[index].instruction === "") {
            event.preventDefault();
            inputRefs.current[index - 1].focus(); // Focus on the previous instruction input
        }
    }

    //on change instruction text 
    const onchangeinstructiontext = (text, key) => {
        // Use map to create a new array and update the specific instruction
        const updatedInstructions = instructions.map((instruction, index) =>
            index === key ? { ...instruction, instruction: text } : instruction
        );
        setinstructions(updatedInstructions);
    };




    //remove instruction
    const removeinstruction = (optionkey) => {
        setinstructions(instructions.filter((obj, key) => key !== optionkey))
    }



    return (
        <Box width={"100%"}>
            <Typography fontFamily={"boldfont"}>Add test Instructions</Typography>
            <Box>
                {instructions.map((instruction, key) => {

                    return (
                        <Stack width={"100%"} key={key} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Customsectiontextinput
                                inputRef={(ref) => (inputRefs.current[key] = ref)}
                                fieldname={"Instruction " + (key + 1)}
                                handleKeyDown={(e) => instructiontextkeydown(e, key)}
                                value={instruction.instruction}
                                onChange={(text) => onchangeinstructiontext(text, key)}
                                width={"90%"}
                            />
                            <Clear sx={{ cursor: "pointer" }} onClick={() => removeinstruction(key)} color="error" />
                        </Stack>
                    )
                })}
            </Box>




        </Box >
    )
}

export default Assessmentbuilder4