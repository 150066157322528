import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { ContentCopy, LanguageOutlined, LocalPhoneOutlined, LocationOnOutlined, School, WorkOutline } from "@mui/icons-material";
import { assessmentsresults, dummymanimage, schoolsAttended } from "../Utils/dummy";
import { copyToClipboard, formatDateRange, openurl, returnperiod } from "../Utils/functions";
import githubIcon from './../../assets/svgs/github.svg';
import googleIcon from './../../assets/svgs/google.svg';
import linkedinIcon from './../../assets/svgs/linkedin.svg';
import { isMobile } from "react-device-detect";
import CircularWithValueLabel from "../Components/circularprogress";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useLocation, useNavigate } from "react-router-dom";
import { boxborder, primarycolor } from "../Utils/colors";
import muistyles from "../Utils/muistyles";
import { Sitenamelogo } from "./sitelogo";
import { Erroralert, Successalert } from "../Components/popups";
import { CandidateDashboardSkeleton } from "../Components/skeletons";
import axios from "axios";
import { Fetchcandidateprofileurl } from "../Utils/urls";
import { site_baseURL } from "../Utils/defaults";
import Nodatacomponent from "../Components/nodatacomponent";

const Candidateprofile = () => {
    const location = useLocation()
    const pathname = location.pathname
    const user_code = pathname.split('/').filter(Boolean).pop();


    const [candidate, setcandidate] = useState('');

    const [loading, setloading] = useState(true);
    const [success, setsuccess] = useState('');
    const [error, seterror] = useState('');

    const profilelink = `${site_baseURL}/candidate-profile/${candidate?.public_code}`


    useEffect(() => {
        fetchcandidates()
    }, []);


    const fetchcandidates = () => {
        setloading(true)
        axios.get(`${Fetchcandidateprofileurl}${user_code}`).then((response) => {
            setloading(false)
            if (response.data.success) {
                setcandidate(response.data?.data?.profile_details)
            } else {
                seterror('Invalid url')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }

    const userinfo = candidate?.user

    const copyprofilelink = () => {
        copyToClipboard(profilelink)
        setsuccess('Profile Link Copied successfully')
    }



    const average_ratings = () => {
        const sum_of_years = candidate?.candidate_skills.reduce((total, skill) => total + parseInt(skill.years_of_experience), 0);
        return (sum_of_years / (candidate?.candidate_skills?.length || 1)).toFixed(1);
    }



    const svgsize = isMobile ? "15px" : '20px'
    return (
        <Box margin={"auto"} width={{ xs: "98", lg: "90%" }}><br />
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack mb={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Sitenamelogo />
                <Button onClick={() => openurl("https://kontorva.com")} variant="contained" color="secondary" sx={{ textTransform: "none" }}>Join Us Today</Button>
            </Stack>
            {!loading &&
                <Box padding={{ xs: 1, lg: 3 }} borderRadius={3} mb={2} sx={{ background: `linear-gradient(to right, ${primarycolor}, #57a2df)`, }}>
                    <Box>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Typography fontSize={{ xs: 14, lg: 20 }} color={'white'} fontFamily={'boldfont'}>Candidate Profile</Typography>
                            <Button onClick={() => copyprofilelink()} sx={muistyles?.whitebutton} startIcon={<ContentCopy />} variant="contained">Share Public Profile</Button>
                        </Stack>
                    </Box>
                </Box>}
            {!loading &&
                <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"} >
                    <Box width={{ xs: "100%", lg: "30%" }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Box height={150} width={"100%"} sx={{ background: "linear-gradient(to right, #f8e7e7, #dcdff9)" }}>
                            <Box padding={2}>
                                <img src={userinfo?.profile_picture_url} className="homeprofileimage" />
                            </Box>
                        </Box>
                        <Box padding={2}>
                            <Typography fontFamily={'boldfont'} fontSize={17}>{userinfo?.name}</Typography>
                            <Typography fontSize={14}>{userinfo?.email}</Typography>
                            <Box mt={2} mb={2}>
                                <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                    <WorkOutline fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{candidate?.specialization}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                    <LocationOnOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{candidate?.state}, {candidate?.country}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                    <LanguageOutlined fontSize="small" color="gray" />
                                    <Typography
                                        color={'gray'}
                                        fontSize={14}
                                        ml={1}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }}
                                    >
                                        {candidate?.linkdin}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                    <LocalPhoneOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{candidate?.phone || '2547 22 222 222'}</Typography>
                                </Stack>
                            </Box>
                            <hr />
                            <Box mt={1} mb={2}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>More Details</Typography>
                                </Stack>
                                <Box >
                                    <Typography fontSize={13}>Current</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>Github</Typography>
                                    <Typography mt={1} fontSize={13}>Years of Experience</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(candidate?.proffessional_experience)}</Typography>
                                    <Typography mt={1} fontSize={13}>Freelance</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(candidate?.freelance_experience)}</Typography>
                                    <Typography mt={1} fontSize={13}>English proficiency</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>{candidate?.english_level}</Typography>
                                    <Typography mt={1} fontSize={13}>Commitment Type</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>{candidate?.commitment_type}</Typography>
                                    <Typography mt={1} fontSize={13}>Expected Pay</Typography>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>{candidate?.monthly_rate} $/month</Typography>
                                </Box>
                            </Box>
                            <hr />
                            <Box mt={1} mb={2}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography fontSize={14} fontFamily={'boldfont'}>Social Accounts</Typography>
                                </Stack>
                                <Stack onClick={() => openurl(candidate?.linkdin)} mt={1} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                    <img src={linkedinIcon} style={{ height: svgsize, width: svgsize }} />
                                    <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Linkedin</Typography>
                                </Stack>
                                <Stack onClick={() => openurl(candidate?.github)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                    <img src={githubIcon} style={{ height: svgsize, width: svgsize }} />
                                    <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Github</Typography>
                                </Stack>
                                {candidate?.custom_link &&
                                    <Stack onClick={() => openurl(candidate.custom_link)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                        <img src={googleIcon} style={{ height: svgsize, width: svgsize }} />
                                        <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Website</Typography>
                                    </Stack>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "68%" }} >
                        <Box sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <CircularWithValueLabel value={68} />
                                <Box ml={2} width={{ xs: "60%", lg: "100%" }}>
                                    <Typography fontFamily={'boldfont'} fontSize={13}>Assessments Completion</Typography>
                                    <Typography fontSize={12}>
                                        Completion percentage of Assessments that are related
                                        to candidates Skills
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Stack mt={2} mb={2} flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                            <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                                <img style={{ maxWidth: 50 }} src={require('./../../assets/icons/receive.png')} />
                                <Box ml={1}>
                                    <Typography fontSize={13}>Points Collected</Typography>
                                    <Typography fontSize={16} fontFamily={'boldfont'}>245</Typography>
                                </Box>
                            </Stack>
                            <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                                <img style={{ maxWidth: 50 }} src={require('./../../assets/icons/rated.png')} />
                                <Box ml={1}>
                                    <Typography fontSize={13}>Assessment Ratings</Typography>
                                    <Typography fontSize={16} fontFamily={'boldfont'}>{average_ratings()}</Typography>
                                </Box>
                            </Stack>
                            <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                                <img style={{ maxWidth: 50 }} src={require('./../../assets/icons/completed.png')} />
                                <Box ml={1}>
                                    <Typography fontSize={13}>Assessment Taken</Typography>
                                    <Typography fontSize={16} fontFamily={'boldfont'}>158</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                        <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <Typography mb={1} mt={1} fontFamily={'boldfont'}>Average Ratings</Typography>
                            <ResponsiveContainer width="95%" height={300}>
                                <BarChart
                                    data={candidate?.candidate_skills}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="skill_name" />
                                    <YAxis />
                                    {/* <Tooltip /> */}
                                    <Bar dataKey="years_of_experience" radius={[10, 10, 10, 10]} barSize={50} fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                        <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Box width={{ xs: "80%", lg: "auto" }}>
                                    <Typography mb={1} mt={1} fontFamily={'boldfont'}>Verified Skills</Typography>
                                    <Typography fontSize={13}>These are the candidate's skiils that have been verified on the platform.</Typography>
                                </Box>
                            </Stack>
                            <Box display={"flex"} flexWrap={"wrap"} mt={1} mb={2}>
                                {candidate?.candidate_skills?.map((skill, key) => {

                                    return (
                                        <Stack key={key} border={boxborder} padding={1} borderRadius={5} mr={1} flexDirection={"row"} alignItems={"center"}>
                                            <Typography fontSize={14} >{skill?.skill_name}</Typography>
                                        </Stack>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography mb={1} mt={1} fontFamily={'boldfont'}>Education</Typography>
                            </Stack>
                            <Box mt={1}>
                                {candidate?.education_details?.map((school, key) => {
                                    return (
                                        <Stack mb={2} key={key} flexDirection={"row"} alignItems={"center"}>
                                            <Box width={40} height={40} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                                <IconButton><School fontSize="medium" /></IconButton>
                                            </Box>
                                            <Box ml={1}>
                                                <Typography fontSize={14} fontFamily={"boldfont"}>{school.institution}</Typography>
                                                <Typography fontSize={13}>
                                                    {school.level} {school.certification} - {formatDateRange(school.start_date, school.end_date)}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    )
                                })}
                                {!candidate?.education_details && !loading && <Nodatacomponent height={100} text={"No education background"} />}
                            </Box>

                        </Box>
                    </Box>
                </Stack>
            }
            {loading && <CandidateDashboardSkeleton />}
        </Box>
    )
}

export default Candidateprofile