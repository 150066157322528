//redux constants
export const reduxconstants = {
  USER_PROFILE: "user",
  USER_PROFILES: "profiles",
  NEW_ASSESSMENT: "newassessment",
  SAVED_CANDIDATES: "saved_candidates"
}


//developer roles
export const developerroles = [
  'Frontend', 'Backend', 'Fullstack (BE-Heavy)', 'Fullstack (FE-Heavy)',
  'Fullstack (Balanced)', 'Design', 'Mobile', 'QA', 'DevOps', 'Data Science',
  'Data Engineering', 'Machine Learning', 'Game Development', 'Business Intelligence',
  'Embeded Engineer', 'Other'
]

export const skilltypes = ['Frameworks', 'Programming Languages', 'Database Technologies', 'Cloud Technologies']

export const programmingLanguagesFrameworks = [
  { label: "React js", value: "react js" },
  { label: "Vue js", value: "vue js" },
  { label: "Angular", value: "angular" },
  { label: "Svelte", value: "svelte" },
  { label: "Next js", value: "next js" },
  { label: "Nuxt js", value: "nuxt js" },
  { label: "Gatsby", value: "gatsby" },
  { label: "Ember js", value: "ember js" },
  { label: "Meteor js", value: "meteor js" },
  { label: "Express js", value: "express js" },
  { label: "NestJS", value: "nestjs" },
  { label: "Django", value: "django" },
  { label: "Flask", value: "flask" },
  { label: "Spring Boot", value: "spring-boot" },
  { label: "Ruby on Rails", value: "ruby-on-rails" },
  { label: "Laravel", value: "laravel" },
  { label: "ASP.NET Core", value: "asp.net-core" },
  { label: "Symfony", value: "symfony" },
  { label: "Phoenix (Elixir)", value: "phoenix-elixir" },
  { label: "Koa js", value: "koa js" },
  { label: "FastAPI", value: "fastapi" },
  { label: "Micronaut", value: "micronaut" },
  { label: "React Native", value: "react-native" },
  { label: "Flutter", value: "flutter" },
  { label: "Ionic", value: "ionic" },
  { label: "Xamarin", value: "xamarin" },
  { label: "Cordova", value: "cordova" },
  { label: "Unity", value: "unity" },
  { label: "Unreal Engine", value: "unreal-engine" },
  { label: "Godot", value: "godot" },
  { label: "Cocos2d", value: "cocos2d" },
  { label: "CryEngine", value: "cryengine" },
  { label: "TensorFlow", value: "tensorflow" },
  { label: "PyTorch", value: "pytorch" },
  { label: "Keras", value: "keras" },
  { label: "Pandas", value: "pandas" },
  { label: "NumPy", value: "numpy" },
  { label: "Scikit-learn", value: "scikit-learn" },
  { label: "Hugging Face Transformers", value: "hugging-face-transformers" },
  { label: "Electron", value: "electron" },
  { label: "Qt", value: "qt" },
  { label: "Tkinter", value: "tkinter" },
  { label: "jQuery", value: "jquery" },
  { label: "Bootstrap", value: "bootstrap" },
  { label: "Tailwind CSS", value: "tailwind-css" },
  { label: "Chakra UI", value: "chakra-ui" },
];



//programming languages
export const programminglanguagesarray = [
  { label: "JavaScript", value: "javascript" },
  { label: "Python", value: "python" },
  { label: "Java", value: "java" },
  { label: "C++", value: "cpp" },
  { label: "C#", value: "csharp" },
  { label: "Ruby", value: "ruby" },
  { label: "PHP", value: "php" },
  { label: "Swift", value: "swift" },
  { label: "Kotlin", value: "kotlin" },
  { label: "Go", value: "go" },
  { label: "Rust", value: "rust" },
  { label: "TypeScript", value: "typescript" },
  { label: "Perl", value: "perl" },
  { label: "Objective-C", value: "objective-c" },
  { label: "Scala", value: "scala" },
  { label: "R", value: "r" },
  { label: "Dart", value: "dart" },
  { label: "Elixir", value: "elixir" },
  { label: "Haskell", value: "haskell" },
];

//database technologies  
export const databaseTechnologiesArray = [
  { label: 'Firebase', value: 'firebase' },
  { label: "MySQL", value: "mysql" },
  { label: "PostgreSQL", value: "postgresql" },
  { label: "MongoDB", value: "mongodb" },
  { label: "SQLite", value: "sqlite" },
  { label: "Oracle", value: "oracle" },
  { label: "Microsoft SQL Server", value: "mssql" },
  { label: "MariaDB", value: "mariadb" },
  { label: "Redis", value: "redis" },
  { label: "Cassandra", value: "cassandra" },
  { label: "Elasticsearch", value: "elasticsearch" },
  { label: "Firebase", value: "firebase" },
  { label: "CouchDB", value: "couchdb" },
  { label: "Neo4j", value: "neo4j" },
  { label: "Amazon DynamoDB", value: "dynamodb" },
  { label: "HBase", value: "hbase" },
  { label: "CockroachDB", value: "cockroachdb" },
  { label: "Realm", value: "realm" },
  { label: "ArangoDB", value: "arangodb" },
  { label: "Teradata", value: "teradata" },
];


export const cloudPlatformsArray = [
  { label: "Amazon Web Services (AWS)", value: "aws" },
  { label: "Microsoft Azure", value: "azure" },
  { label: "Google Cloud Platform (GCP)", value: "gcp" },
  { label: "IBM Cloud", value: "ibm-cloud" },
  { label: "Alibaba Cloud", value: "alibaba-cloud" },
  { label: "Oracle Cloud", value: "oracle-cloud" },
  { label: "Salesforce Cloud", value: "salesforce-cloud" },
  { label: "DigitalOcean", value: "digitalocean" },
  { label: "Heroku", value: "heroku" },
  { label: "Linode", value: "linode" },
  { label: "Vercel", value: "vercel" },
  { label: "Netlify", value: "netlify" },
  { label: "Docker Cloud", value: "docker-cloud" },
  { label: "Red Hat OpenShift", value: "openshift" },
  { label: "Cloudflare", value: "cloudflare" },
  { label: "Tencent Cloud", value: "tencent-cloud" },
  { label: "Raspberry Pi Cloud", value: "raspberry-pi-cloud" },
  { label: "Zoho Cloud", value: "zoho-cloud" },
  { label: "Kubernetes Engine (GKE)", value: "gke" },
];



//skill levels 
export const skilllevels = [
  {
    label: 'Basic',
    description: 'Foundational understanding; can perform simple tasks with guidance.'
  },
  {
    label: 'Intermediate',
    description: 'Can work independently on standard tasks; good understanding with occasional need for help.'
  },
  {
    label: 'Advanced',
    description: 'In-depth knowledge; handles complex tasks and guides others with rare need for assistance.'
  },
  {
    label: 'Expert',
    description: 'Highly proficient; manages any task, innovates, and mentors others.'
  }
];

export const englishLevels = [
  {
    label: 'Basic',
    description: 'Can understand and use simple phrases; communicates in basic situations.'
  },
  {
    label: 'Intermediate',
    description: 'Can handle most situations while traveling; has a good grasp of grammar and vocabulary for everyday conversations.'
  },
  {
    label: 'Advanced',
    description: 'Can understand and discuss complex topics; speaks fluently and naturally in both professional and social settings.'
  },
  {
    label: 'Expert',
    description: 'Has near-native proficiency; communicates effortlessly and precisely, with excellent command of nuance and idiomatic expressions.'
  }
];



//it degrees
export const ITDegrees = [
  { label: "Bachelor of Science in Computer Science", value: "bsc_computer_science" },
  { label: "Bachelor of Science in Information Technology", value: "bsc_information_technology" },
  { label: "Bachelor of Science in Software Engineering", value: "bsc_software_engineering" },
  { label: "Bachelor of Science in Cybersecurity", value: "bsc_cybersecurity" },
  { label: "Bachelor of Science in Data Science", value: "bsc_data_science" },
  { label: "Bachelor of Science in Artificial Intelligence", value: "bsc_artificial_intelligence" },
  { label: "Bachelor of Science in Information Systems", value: "bsc_information_systems" },
  { label: "Bachelor of Science in Network Engineering", value: "bsc_network_engineering" },
  { label: "Bachelor of Science in Web Development", value: "bsc_web_development" },
  { label: "Bachelor of Science in Computer Engineering", value: "bsc_computer_engineering" },
  { label: "Master of Science in Information Technology", value: "msc_information_technology" },
  { label: "Master of Science in Data Analytics", value: "msc_data_analytics" },
  { label: "Master of Science in Cybersecurity", value: "msc_cybersecurity" },
  { label: "Master of Science in Software Engineering", value: "msc_software_engineering" },
  { label: "Master of Science in Computer Science", value: "msc_computer_science" }
];
export const ITPrograms = [
  { label: "Certificate", value: "certificate" },
  { label: "Diploma", value: "diploma" },
  { label: "Associate Degree", value: "associate_degree" },
  { label: "Bachelor's Degree", value: "bachelors_degree" },
  { label: "Master's Degree", value: "masters_degree" },
  { label: "PhD", value: "phd" },
  { label: "Professional Certification", value: "professional_certification" },
  { label: "Short Course", value: "short_course" }
];

export const alphabets = ['A', 'B', 'C', 'D', 'E', 'F']

//code editor themes
export const editorthemes = ["light", "vs-dark"]


// Code editor languages supported with IDs and sample code
export const editorsupportedlanguages = [
  { label: "JavaScript", value: "javascript", id: 63, sampleCode: "console.log(`the sum of 30 + 40=${30+40}`);" },
  { label: "TypeScript", value: "typescript", id: 74, sampleCode: "console.log('Hello, TypeScript!');" },
  { label: "CSS", value: "css", id: 0, sampleCode: "body { background-color: lightblue; }" },
  { label: "HTML", value: "html", id: 0, sampleCode: "<!DOCTYPE html><html><body><h1>Hello, World!</h1></body></html>" },
  { label: "JSON", value: "json", id: 0, sampleCode: "{ \"message\": \"Hello, World!\" }" },
  { label: "XML", value: "xml", id: 0, sampleCode: "<message>Hello, World!</message>" },
  { label: "Markdown", value: "markdown", id: 0, sampleCode: "# Hello, World!" },
  { label: "YAML", value: "yaml", id: 0, sampleCode: "message: Hello, World!" },
  { label: "SQL", value: "sql", id: 82, sampleCode: "SELECT 'Hello, World!';" },
  { label: "Java", value: "java", id: 62, sampleCode: "public class HelloWorld { public static void main(String[] args) { System.out.println('Hello, World!'); } }" },
  { label: "Python", value: "python", id: 71, sampleCode: "print('Hello, World!')" },
  { label: "C++", value: "cpp", id: 76, sampleCode: "#include <iostream>\nint main() { std::cout << 'Hello, World!' << std::endl; return 0; }" },
  { label: "C#", value: "csharp", id: 51, sampleCode: "using System;\nclass Program { static void Main() { Console.WriteLine('Hello, World!'); } }" },
  { label: "PHP", value: "php", id: 68, sampleCode: "<?php echo 'Hello, World!'; ?>" },
  { label: "Go", value: "go", id: 60, sampleCode: "package main\nimport \"fmt\"\nfunc main() { fmt.Println('Hello, World!') }" },
  { label: "Ruby", value: "ruby", id: 72, sampleCode: "puts 'Hello, World!'" },
  { label: "Swift", value: "swift", id: 83, sampleCode: "print('Hello, World!')" },
  { label: "Razor", value: "razor", id: 0, sampleCode: "@{ var message = \"Hello, World!\"; }\n<p>@message</p>" },
  { label: "Objective-C", value: "objective-c", id: 79, sampleCode: "#import <Foundation/Foundation.h>\nint main() {\n    NSLog(@'Hello, World!');\n    return 0;\n}" },
  { label: "Pug", value: "pug", id: 0, sampleCode: "html\n  head\n    title Hello\n  body\n    h1 Hello, World!" },
  { label: "Dockerfile", value: "dockerfile", id: 0, sampleCode: "FROM alpine\nRUN echo 'Hello, World!'" },
  { label: "PowerShell", value: "powershell", id: 0, sampleCode: "Write-Output 'Hello, World!'" },
  { label: "Shell Script", value: "shell", id: 46, sampleCode: "echo 'Hello, World!'" },
  { label: "Less", value: "less", id: 0, sampleCode: "@color: #4D926F;\n#header { color: @color; }" },
  { label: "SCSS", value: "scss", id: 0, sampleCode: "$color: #4D926F;\n#header { color: $color; }" }
];



export const feedbackaffectedFeatures = [
  "Developer Profile Visibility",
  "Coding Challenge Assessments",
  "Certification System",
  "Custom Test Administration for Clients",
  "Developer Search & Filtering",
  "Developer Certification Display",
  "Client-Developer Communication",
  "Assessment Result Reporting",
  "Test Feedback and Review System",
  "Notifications & Alerts for Custom Tests",
  "Client Dashboard for Developer Selection",
  "Developer Skill Categorization",
  "Performance Analytics for Developers",
  "Platform Onboarding & Guidance"
];

export const companysizes = ["2-10 Employees", "11-50 Employees", "51-200 Employees"]

export const companyTypes = [
  "Information Technology (IT)",
  "Logistics",
  "Healthcare",
  "Manufacturing",
  "Retail",
  "Construction",
  "Finance",
  "Education",
  "Agriculture",
  "Telecommunications",
  "Hospitality",
  "Real Estate",
  "Energy",
  "Transportation",
  "Consulting",
  "Media and Entertainment",
  "Pharmaceuticals",
  "Aerospace and Defense",
  "Automotive",
  "E-commerce",
  "Food and Beverage",
  "Insurance",
  "Biotechnology",
  "Legal Services",
  "Mining"
];



export const experiencelevels = [
  {
    label: "Less than a Year",
    min: 0,
    max: 12
  },
  {
    label: "1-3 Years",
    min: 12,
    max: 36
  },
  {
    label: "2-5 Years",
    min: 24,
    max: 60
  },
  {
    label: "5-10 Years",
    min: 60,
    max: 120
  }
];


//assessment builder stepa
export const assessmentbuildersteps = [
  "Role Details", "Choose Tests", "Custom Questions", "Finalize"
]

export const commitmenttypes = [
  'Full-time', 'Part-time', 'Hourly', "I'm not really sure"
]

//question types
export const Questiontypes = [
  "Multiple Choices", "Open", "Coding"
]


export const site_faqs = [
  {
    question: "What is Kontorva?",
    answer: "Kontorva is a platform that connects developers with clients. Developers can complete coding challenges to showcase their skills, get certified, and have their profiles visible to clients. Clients can find qualified developers based on their needs without posting jobs or placing bids."
  },
  {
    question: "How do clients find developers on Kontorva?",
    answer: "Clients can search for developers based on skills, certifications, and performance on coding challenges. They can also administer custom tests to developers they are interested in hiring."
  },
  {
    question: "Do clients need to post jobs on Kontorva?",
    answer: "No, clients do not post jobs or place bids on Kontorva. Instead, they search for qualified developers and reach out to those who match their requirements."
  },
  {
    question: "How do developers get certified?",
    answer: "Developers on Kontorva complete coding challenges that assess their skills. Based on their performance, they receive certifications that become visible on their profiles, which clients can view."
  },
  {
    question: "Can clients administer custom tests to developers?",
    answer: "Yes, clients can create and administer custom tests to developers they are interested in. This allows them to evaluate specific skills before making a hiring decision."
  },
  {
    question: "Is there a fee for developers to join Kontorva?",
    answer: "No, developers can join Kontorva for free and participate in coding challenges to build their profiles and get certified."
  },
  {
    question: "What kind of support does Kontorva offer for clients?",
    answer: "Kontorva offers various support plans depending on the subscription. From basic client support to a dedicated account manager, clients can choose the level of support that fits their needs."
  },
  {
    question: "How does Kontorva ensure the quality of developers?",
    answer: "Kontorva uses coding challenges and assessments to verify the skills of developers. Certifications and performance data are visible on their profiles, helping clients make informed decisions."
  },
  {
    question: "What are the benefits of getting certified on Kontorva?",
    answer: "By getting certified through Kontorva's coding challenges, developers can showcase their skills to potential clients. Certified developers have higher visibility and are more likely to be hired by clients."
  },
  {
    question: "How do clients contact developers?",
    answer: "Once a client finds a developer that matches their needs, they can contact the developer directly through Kontorva to discuss project details and initiate the hiring process."
  }
];
