import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import { assessmentsdummy, rolesdummy, systemassessments } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { AccessAlarms, AlarmOn, ArrowRightAlt, BookmarkOutlined, BorderColorOutlined, LocationOn, NewReleases, ReportProblem, TuneOutlined, Verified } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertTimestampToTime } from "../../../Utils/functions";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Fetchassesmentsurl, Fetchassessmentresultsurl, Fetchcandidaterolesurl, Fetchrolesinvitesurl } from "../../../Utils/urls";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../Components/skeletons";

const Candidateassessments = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const [invited_roles, setinvited_roles] = useState([]);
    const [all_assessments, setall_assessments] = useState([]);
    const [assessments_results, setassessments_results] = useState([]);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Skill Assessment' }))
        }
        fetchpredata()
    }, []);


    const fetchpredata = async () => {
        setloading(true)
        const [rolesResponse, assessmentsResponse, assessmentsResultsResponse] = await Promise.all([
            axiosInstance.get(Fetchrolesinvitesurl),
            axiosInstance.get(Fetchassesmentsurl),
            axiosInstance.get(Fetchassessmentresultsurl)
        ])

        setloading(false)
        if (rolesResponse.data) {
            const received_roles = rolesResponse.data.data.invites
            setinvited_roles(received_roles)
        }

        if (assessmentsResponse.data) {
            console.log(assessmentsResponse.data)
            const received_assessments = assessmentsResponse.data.data.assessments
            setall_assessments(received_assessments)
        }

        if (assessmentsResultsResponse.data) {
            const received_results = assessmentsResultsResponse.data.data.assessments
            setassessments_results(received_results)
        }
    }





    const gotoassessments = (assessment) => {
        navigate("/candidateaccount/assessments-module", { state: { assessmentinfo: assessment } })
    }

    const gottorole = (role) => {
        navigate("/candidateaccount/invited-role", { state: { invited_role: role } })

    }

    const average_ratings = () => {
        const sum_of_years = user?.candidate_skills.reduce((total, skill) => total + parseInt(skill.years_of_experience), 0);
        return (sum_of_years / (user?.candidate_skills?.length || 1)).toFixed(1);
    }


    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            <Typography fontFamily={'boldfont'}>My Assessment</Typography>
            <Stack flexDirection={{ xs: "column", lg: "row" }} mt={1} justifyContent={"space-between"}  >
                <Box width={{ xs: "100%", lg: "68%" }} >
                    <Stack flexDirection={"row"} width={"100%"} overflow={{ xs: "scroll", lg: "hidden" }} >
                        {!loading && invited_roles?.slice(0, 3).map((invite, key) => {
                            const role = invite.role

                            return (
                                <Box position={"relative"} margin={"1%"} key={key} width={{ xs: "48", lg: "30%" }} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <IconButton sx={{ right: 0, top: 0, position: "absolute" }} size="small"><BookmarkOutlined /></IconButton>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"} >
                                        <Typography width={"60%"} fontFamily={"boldfont"}>{role.position}({role.skill_level})</Typography>
                                    </Stack>
                                    <hr color="silver" />
                                    <Stack mb={1} mt={1} >
                                        <Stack flexDirection={"row"} alignItems={"center"}>
                                            <LocationOn color="primary" fontSize="13px" />
                                            <Typography sx={{ ...muistyles.twolinetextlimit, WebkitLineClamp: 1 }} ml={1} fontSize={13}>{role.location}</Typography>
                                        </Stack>
                                        <Stack flexDirection={"row"}>
                                            <AccessAlarms color="primary" fontSize="13px" />
                                            <Typography ml={1} fontSize={13}>{role.work_place_type}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Button fullWidth onClick={() => gottorole(invite)} endIcon={<ArrowRightAlt />} color="secondary" variant="outlined" sx={{ textTransform: "none", height: 25 }}>View Role</Button>
                                </Box>
                            )
                        })}

                    </Stack>
                    {loading && <Skeleton variant="rectangular" sx={{ height: 150, width: "100%" }} />}
                    {!loading && invited_roles.length < 1 &&
                        <Box width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Nodatacomponent height={60} text={"You have no invited roles yet"} />
                        </Box>}
                    <Stack flexDirection={"row"} justifyContent={"right"} alignItems={"flex-end"}>
                        {invited_roles.length > 0 && <Button onClick={() => navigate("/candidateaccount/all-my-assessments")} variant="text" sx={{ fontSize: 13 }}>See all</Button>}
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontFamily={'boldfont'}>Get Your Skill Certified</Typography>
                        <TuneOutlined sx={{ cursor: "pointer" }} />
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} flexWrap={"wrap"}>
                        {all_assessments.map((assessment, key) => {
                            const assessmentresult = assessments_results.find((obj) => obj.assessment_id == assessment.id)
                            const status = returnstatus(assessmentresult)

                            return (
                                <Box margin={"1%"} key={key} width={{ xs: "100%", lg: "30%" }} position={"relative"} height={180} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                        {status === "Failed" && <NewReleases titleAccess="You Failed" color="error" />}
                                        {status === "Passed" && <Verified titleAccess="Passed" color="success" />}
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                    <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                        <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                                    </Box>
                                    <Stack mt={2} position={"absolute"} bottom={15} width={"95%"}>
                                        {(status === 'Not started' || status === 'Failed') &&
                                            <Button fullWidth onClick={() => gotoassessments(assessment)} variant="outlined" color="secondary" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                                {status === 'Not started' ? 'Start Assessment' : 'Re-take Assessment'}
                                            </Button>
                                        }
                                        {status === 'Passed' && <Button fullWidth variant="contained" color="success" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>Passed</Button>}
                                    </Stack>
                                </Box>
                            )
                        })}
                        {loading && Array.from({ length: 10 }, (_, index) => (
                            <Assessmentskeleton key={index} />
                        ))}
                    </Stack>
                    {!loading && all_assessments.length < 1 &&
                        <Box width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Nodatacomponent height={200} text={"No system assessments yet"} />
                        </Box>
                    }
                </Box>
                <Box width={"30%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Typography fontFamily={"boldfont"} fontSize={14}>Your Stats</Typography>
                    <Stack mt={2} mb={2} justifyContent={"space-between"}>
                        <Stack width={"100%"} mb={2} alignItems={"center"} flexDirection={"row"} >
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/receive.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Points Collected</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>245</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"100%"} mb={2} alignItems={"center"} flexDirection={"row"} >
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/rated.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Ratings</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>{average_ratings()}</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"100%"} alignItems={"center"} flexDirection={"row"}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/completed.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Taken</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>158</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <hr />
                    <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                        <CircularWithValueLabel value={68} />
                        <Box ml={2}>
                            <Typography fontFamily={'boldfont'} fontSize={13}>Complete Your Skills Assessments</Typography>
                            <Typography fontSize={12}>
                                Complete the assessments related to your skills profile to
                                be active and discoverable to recruiters looking for you
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </Stack>

        </Box>
    )
}


const returnstatus = (assessmentresult) => {
    if (assessmentresult?.passed == 1) {
        return "Passed"
    }
    if (assessmentresult?.score && assessmentresult !== 1) {
        return "Failed"
    }
    return 'Not started'

}


export default Candidateassessments