import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Container, IconButton, Modal, Stack, Typography } from "@mui/material"
import { Customreactselectinput } from "../../../Components/textinputs.";
import { Clear } from "@mui/icons-material";
import Popupdialog from "../../../Components/popupcomponent";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Fetchcandidaterolesurl, Shortlistcandidatesurl } from "../../../Utils/urls";
import { Erroralert, Successalert } from "../../../Components/popups";

//add education modal
export const Shortlistmodal = ({ ondismiss, candidate }) => {
    const axiosInstance = useAuthAxios();
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);
    const [current_role, setcurrent_role] = useState('');

    const [roles, setroles] = useState([]);


    const [confirmsave, setconfirmsave] = useState(false);

    useEffect(() => {
        fetchroles()
    }, []);

    const fetchroles = () => {
        setloading(true)
        axiosInstance.get(Fetchcandidaterolesurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                const received_roles = response.data.data.roles
                setroles(received_roles.map((val) => ({
                    label: `${val.position}(${val.skill_level})`,
                    value: val.id
                })))
            } else {
                seterror('No roles yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }

    const handleSubmit = () => {
        setconfirmsave(false)
        setloading(true)
        axiosInstance.post(Shortlistcandidatesurl, {
            role_id: current_role.value,
            candidate_id: [candidate.id]
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                setsuccess('Shortlisted successfully')
                setTimeout(() => {
                    setsuccess('')
                    ondismiss()
                }, 3000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }




    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                {confirmsave &&
                    <Popupdialog
                        toptext={"Confirm Shortlisting!"}
                        question={"Are you sure you want to shortlist this candidate?"}
                        successtext={"Shortlist"}
                        oncancel={() => setconfirmsave(false)}
                        onsuccess={handleSubmit}
                    />
                }
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>Shortlist to a role</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>

                <Box>
                    <Customreactselectinput
                        options={roles}
                        value={current_role}
                        onChange={setcurrent_role}
                        fieldname={'Role'}
                        hint={"i.e React Developer"}
                    /><br />
                    <Stack mt={3} flexDirection={"row"} justifyContent={"right"} alignItems={'flex-end'}>
                        <Button onClick={() => ondismiss()} variant="text">Cancel</Button>&nbsp;
                        <Button onClick={() => setconfirmsave(true)} disabled={!current_role} type="submit" variant="contained">{loading ? <CircularProgress sx={{ color: "white" }} size={15} /> : 'Shortlist'}</Button>
                    </Stack>
                </Box>
            </Container>
        </Modal>
    )
}

