import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DriveFileRenameOutline, East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import muistyles from "../../../../Utils/muistyles";
import { removeNewassessment } from "../../../../../store/newassessment";




const Assessmentbuilder5 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const dispatch = useDispatch()


    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);

    //if user is not from the pervious final details page
    if (!newassessment.instructions) {
        navigate("/clientaccount/clientassessments")
    }





    const gotonext = () => {
        navigate("/clientaccount/clientassessments")
        dispatch(removeNewassessment())
    }

    // role, country, city, commitmenttype


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Finalize</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={4} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {newassessment &&
                    <Box mb={2} sx={{ height: "60vh", overflowY: "scroll" }}>
                        <Box mt={2} padding={2} sx={{ border: boxborder, borderRadius: 2 }}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={18} fontFamily={"boldfont"}>{newassessment.role}</Typography>
                                <Button onClick={() => navigate(-4)} endIcon={<DriveFileRenameOutline />}>Edit</Button>
                            </Stack>
                            <Typography fontSize={14} fontFamily={"boldfont"}>{newassessment.city}, {newassessment.country}</Typography>
                            <Typography fontSize={14} >{newassessment.commitmenttype}</Typography>
                        </Box>
                        <Box mt={2} padding={2} sx={{ border: boxborder, borderRadius: 2 }}>
                            <Typography>System</Typography>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={18} fontFamily={"boldfont"}>Choosen Tests</Typography>
                                <Button onClick={() => navigate(-3)} endIcon={<DriveFileRenameOutline />}>Edit</Button>
                            </Stack>
                            <Stack flexDirection={"row"} flexWrap={"wrap"}>
                                {newassessment.selectedasessments?.slice(0, 6).map((assessment, key) => {
                                    return (
                                        <Box margin={"1%"} key={key} width={"30%"} padding={2} sx={{ backgroundColor: 'white', border: '2px solid blue', borderRadius: 2, cursor: "pointer" }}>
                                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                                <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                            </Stack>
                                            <Tooltip title={assessment.description}>
                                                <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                                    {assessment.description}
                                                </Typography>
                                            </Tooltip>
                                            <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                                <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                                            </Box>
                                        </Box>
                                    )
                                })}

                            </Stack>
                        </Box>
                        <Box mt={2} padding={2} sx={{ border: boxborder, borderRadius: 2 }}>
                            <Typography>Custom</Typography>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={18} fontFamily={"boldfont"}>Questions ({newassessment?.customquestions.length})</Typography>
                                <Button onClick={() => navigate(-1)} endIcon={<DriveFileRenameOutline />}>Edit</Button>
                            </Stack>
                            <Box>
                                {newassessment?.customquestions.map((question, key) => {

                                    return (
                                        <Box position={"relative"} mb={2} padding={2} sx={{ border: boxborder, borderRadius: 3 }} key={key}>
                                            <Stack flexDirection={"row"}>
                                                <Typography mr={1} fontFamily={"boldfont"}>{key + 1})</Typography>
                                                <Box width={"92%"}>
                                                    <Typography fontFamily={"boldfont"}>{question.question_text}</Typography>
                                                    <Typography >{question.question_type}</Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>

                        <Box mt={2} padding={2} sx={{ border: boxborder, borderRadius: 2 }}>
                            <Typography>Test</Typography>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={18} fontFamily={"boldfont"}>Instructions</Typography>
                                <Button onClick={() => navigate(-1)} endIcon={<DriveFileRenameOutline />}>Edit</Button>
                            </Stack>
                            <Box>
                                {newassessment?.instructions.map((instruction, key) => {

                                    return (
                                        <Box position={"relative"} mb={1} key={key}>
                                            <Stack flexDirection={"row"}>
                                                <Typography mr={1} fontFamily={"boldfont"}>{key + 1})</Typography>
                                                <Box width={"92%"}>
                                                    <Typography >{instruction.instruction}</Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>



                    </Box>}
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button onClick={gotonext} sx={buttonstyle} endIcon={<East />} variant="contained">Finish</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Assessmentbuilder5