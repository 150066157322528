import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, Slider, CircularProgress } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import { Customsectiontextinput } from "../../../Components/textinputs."
import muistyles from "../../../Utils/muistyles"
import { Erroralert } from "../../../Components/popups"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Updateuserinfourl } from "../../../Utils/urls"

const Onboarding5 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]

    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const [monthly_rate, setmonthly_rate] = useState(user?.monthly_rate || '');


    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            monthly_rate: monthly_rate
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, monthly_rate }
                dispatch(setUser(updateddata))
                navigate("/candidate-onboarding-6")
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }


    const gotonext = () => {
        if (monthly_rate) {
            Postdata()
        }
    }


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={70} />
                    </Box>
                    <Box width={"90%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} mb={4} fontSize={30} fontFamily={"boldfont"}>
                            What's your minimum preferred monthly rate?
                        </Typography>
                        <Box mt={3}>
                            <Typography color={'#959593'} fontSize={15} fontFamily={'boldfont'}>I want to earn per month (full-time, before tax)</Typography>
                            <br />
                            <Customsectiontextinput
                                width={"70%"}
                                type={'number'}
                                value={monthly_rate}
                                onChange={setmonthly_rate}
                                onEnter={() => gotonext()}
                                inputProps={{
                                    endAdornment: (
                                        <Typography>$/month</Typography>
                                    ),
                                }}
                            />
                            <Typography fontSize={14} mt={3} color={'#959593'} fontFamily={'italiclightfont'} width={"70%"}>
                                **Note:** We do not place bids on jobs; our role is solely to
                                connect you with clients. This information helps clients
                                understand your expected compensation.
                            </Typography>
                        </Box>
                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={!monthly_rate} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ maxHeight: 150 }} src={require("./../../../../assets/illustrations/payillustration.png")} />
                    <Typography mt={3} fontFamily={'boldfont'} width={"80%"} color={"white"} fontSize={30} textAlign={"center"}>
                        Lets us know Your rates
                    </Typography>
                    <Typography mt={2} width={"80%"} fontFamily={"italiclightfont"} color={'white'} fontSize={15} textAlign={"center"}>
                        Sharing your rates helps clients understand your expected compensation and makes it easier for them to consider you for their projects.
                    </Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding5