import { useEffect, useRef, useState } from "react"
import { CheckCircle, Clear, KeyboardBackspace, PlayArrow } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { boxborder } from "../../../Utils/colors"
import Popupdialog from "../../../Components/popupcomponent"
import { assessment_questions } from "../../../Utils/dummy"
import { CustomStopwatch, navigateto } from "../../../Utils/functions"
import { alphabets, editorsupportedlanguages, editorthemes, phpSuggestions, Questiontypes } from "../../../Utils/arrays"
import { Customcheckbox, Customreactselectinput, Customselectinput } from "../../../Components/textinputs."
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
import { Erroralert } from "../../../Components/popups"
import { useSelector } from "react-redux"
import axios from "axios"
import { rapidapikey } from "../../../Utils/defaults"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Executecodeurl, Fetchassessmentsquestionsurl, Getcandidateassessmentsurl, Gradeassessmenturl, idebase_url, Postquestionanswerurl, Submitreviewurl } from "../../../Utils/urls"
import Loadingspinner from "../../../Components/loading"
import Successspinner from "../../../Components/successspinner"
var base64 = require('base-64');


const Reviewassessment = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const location = useLocation()
    const assessmentinfo = location.state?.assessmentinfo
    const candidate = location.state?.candidate
    const [loading, setloading] = useState(true);
    const [success, setsuccess] = useState('');
    const [error, seterror] = useState('');

    const [all_questions, setall_questions] = useState([]);


    const [screen, setscreen] = useState(1);





    useEffect(() => {
        fetchquestions()
    }, []);

    const fetchquestions = () => {
        axiosInstance.get(`${Getcandidateassessmentsurl}/${assessmentinfo.id}/${candidate.id}`).then((response) => {
            setloading(false)
            if (response.data.success) {
                setall_questions(response.data.data.submission_details)
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }




    return (
        <Box width={"100%"}>
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"}>
                    <KeyboardBackspace onClick={() => screen === 2 ? seterror('Cannot go back in between a test') : navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>{assessmentinfo?.name}</Typography>
                </Stack>
            </Stack>
            <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {all_questions.length > 0 && <Questionscomponent candidate={candidate} questions={all_questions} />}
            </Box>
        </Box>
    )
}


export default Reviewassessment


const Questionscomponent = ({ questions, candidate }) => {
    const navigate = useNavigate()
    const axiosInstance = useAuthAxios()
    const [runningcode, setrunningcode] = useState(false);
    const [codeoutput, setcodeoutput] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState(false);
    const [loading, setloading] = useState(false);


    const [allquestions, setallquestions] = useState(questions || []);
    const [currentquestion, setcurrentquestion] = useState(questions[0]);

    const currentid = (allquestions.findIndex((obj) => obj.id === currentquestion.id)) + 1
    const question_type = currentquestion.question?.question_type

    const [currenttheme, setcurrenttheme] = useState(editorthemes[1]);
    const [currentlanguage, setcurrentlanguage] = useState(editorsupportedlanguages[0]);

    // useEffect(() => {
    //     if (currentlanguage && currentquestion.question_type === Questiontypes[2]) {
    //         setcurrentquestion({ ...currentquestion, questionanswer: currentlanguage.sampleCode })
    //     }
    // }, [currentlanguage]);

    useEffect(() => {
        if (codeoutput) {
            ///scroll to the output box
            navigateto('boxend')
        }
    }, [codeoutput]);


    const gotonext = (is_correct) => {
        setloading(true)
        const clonedquestions = [...allquestions]
        clonedquestions[currentid - 1] = currentquestion
        const nextquestion = allquestions[currentid]

        axiosInstance.post(Submitreviewurl, {
            candidate_id: candidate.id,
            assessment_id: currentquestion.assessment_id,
            question_id: currentquestion.id,
            correct: is_correct
        }).then((response) => {
            if (response.data.success) {
                if (currentid == allquestions.length) {//if its the last question
                    setsuccess(true);
                    setTimeout(() => {
                        setsuccess(false);
                        navigate("/clientaccount/clientassessments", { replace: true });
                    }, 3000);
                } else {
                    setloading(false)
                    setallquestions(clonedquestions)
                    setcurrentquestion(nextquestion)
                }
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }






    const gotback = () => {
        setcurrentquestion(allquestions[currentid - 2])
    }

    // onchnge multiple selection
    const selectoption = (option) => {
        setcurrentquestion({ ...currentquestion, selectedoptionid: option.id })
    }




    //run code
    const runcode = async () => {
        setcodeoutput('');
        setrunningcode(true);

        await axios.post(Executecodeurl, {
            language_id: currentlanguage?.id,
            source_code: currentquestion?.questionanswer,
            stdin: ''
        }).then((response) => {
            const submission_token = response.data.token
            if (submission_token) {
                checkSubmissionStatus(response.data.token);
            } else {
                setrunningcode(false);
                seterror('An error occurred executing your code')
            }
        }).catch((error) => {
            setrunningcode(false);
            seterror('Compiler Unreachable');
            console.error(error);
        })
    };



    const checkSubmissionStatus = async (submission_token) => {
        await axios.get(`${Executecodeurl}/${submission_token}`, {
            params: {
                base64_encoded: 'true',
                fields: '*'
            }
        }).then((response) => {
            const { status, stdout, stderr, message } = response.data;

            if (status.description === 'Processing' || status.description === 'In Queue') {
                // Check status again after 1 second
                setTimeout(() => checkSubmissionStatus(submission_token), 1000);
            } else {
                setrunningcode(false);
                if (!stderr) {
                    setcodeoutput(stdout ? base64.decode(stdout) : 'Code executed successfully with no output');
                } else {
                    seterror(base64.decode(message));
                }
            }
        }).catch(error => {
            setrunningcode(false);
            seterror('Error compiling your code');
        })
    };






    const buttonstyle = { borderRadius: 10, height: 40, width: 100, fontFamily: "boldfont" }

    return (
        <Box width={"100%"} height={"70vh"} >
            {loading && <Loadingspinner />}
            {success && < Successspinner message={"Assessment review complete!!"} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Box height={"90%"} sx={{ overflowY: "scroll", overflowX: 'hidden' }} position={"relative"}>
                <Stack flexDirection={"row"}>
                    <Typography fontFamily={"boldfont"}>{currentid}/{questions?.length}</Typography>
                    <Typography ml={1}>{currentquestion?.question?.question_text}</Typography>
                </Stack>
                <Box mt={2} height={"100%"}>
                    {question_type === Questiontypes[1] &&
                        <Box>
                            <TextField
                                label="Type your answer"
                                multiline
                                rows={8}
                                variant="outlined"
                                value={currentquestion?.answer_text}
                                fullWidth
                            />
                        </Box>}
                    {question_type === Questiontypes[0] &&
                        <Box padding={2}>
                            {currentquestion.options?.map((option, optionkey) => {
                                const isselected = option.id === currentquestion?.selectedoptionid

                                return (
                                    <Stack width={"100%"} onClick={() => selectoption(option)} key={optionkey} mb={3} flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} sx={{ cursor: "pointer" }}>
                                        <Stack flexDirection={"row"}>
                                            <Typography>{alphabets[optionkey]})</Typography>
                                            <Typography ml={1}>{option.option_text}</Typography>
                                        </Stack>
                                        <Customcheckbox type={'radio'} value={isselected} />
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                    {question_type === Questiontypes[2] &&
                        <Box height={"100%"}>
                            <Stack mb={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Customreactselectinput
                                    fieldname={'Language'}
                                    options={editorsupportedlanguages}
                                    width={'40%'}
                                    value={currentlanguage}
                                    onChange={setcurrentlanguage}
                                />
                                <Customselectinput
                                    options={editorthemes}
                                    fieldname={'Theme'}
                                    width={'40%'}
                                    value={currenttheme}
                                    onChange={setcurrenttheme}
                                />
                            </Stack>
                            <Editor
                                theme={currenttheme}
                                height="80%"
                                defaultLanguage={'javascript'}
                                language={currentlanguage?.value}
                                defaultValue={currentlanguage?.sampleCode}
                                value={currentquestion?.questionanswer}
                            />

                        </Box>
                    }
                    {question_type === Questiontypes[2] && codeoutput &&
                        <Box>
                            <Typography fontFamily={'boldfont'}>Output</Typography>
                            <Box mt={2}>
                                {codeoutput}
                            </Box>
                            <div id="boxend" style={{ marginTop: 100 }} />
                        </Box>
                    }

                </Box>

            </Box>
            <Stack mt={2} width={"100%"} sx={{ backgroundColor: "white" }} flexDirection={"row"} justifyContent={"space-between"}>
                <Button disabled={currentid === 1} sx={buttonstyle} onClick={() => gotback()} variant="outlined">Back</Button>
                <Stack flexDirection={"row"}>

                    {question_type === Questiontypes[2] && currentlanguage?.id !== 0 &&
                        <Button disabled={!currentquestion?.questionanswer} sx={{ ...buttonstyle, width: 150 }} startIcon={<PlayArrow />}
                            onClick={() => runcode()} variant="outlined">
                            {runningcode ? <CircularProgress color="primary" size={20} /> : 'RUN CODE'}
                        </Button>}&nbsp;&nbsp;
                    <Button onClick={() => gotonext(false)} endIcon={<Clear />} color="error">Incorrect</Button>
                    <Button onClick={() => gotonext(true)} endIcon={<CheckCircle />} color="success">Correct</Button>
                </Stack>
            </Stack>
        </Box>
    )
}


