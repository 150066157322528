import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, IconButton, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import { Bookmark, BookmarkAdd, BookmarkOutlined, DataObject, ExpandLess, ExpandMore, KeyboardBackspace, LocationOn, LocationOnOutlined, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { Customcheckbox, Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { cloudPlatformsArray, databaseTechnologiesArray, experiencelevels, programminglanguagesarray, programmingLanguagesFrameworks } from "../../../Utils/arrays";
import countryList from 'react-select-country-list'
import { asessmentcandidates, dummymanimage } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../Components/skeletons";
import { saveCandidates } from "../../../../store/saved_candidates";
import { Erroralert, Successalert } from "../../../Components/popups";
import { Fetchcandidaterolesurl, Fetchcandidatesurl } from "../../../Utils/urls";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { openurl, returnperiod } from "../../../Utils/functions";
import { site_baseURL } from "../../../Utils/defaults";
import { Shortlistmodal } from "./shortlistmodal";



const Discovercandidates = () => {
    const navigate = useNavigate()
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const { saved_candidates } = useSelector((state) => state.saved_candidates);
    const dispatch = useDispatch()
    const countryoptions = useMemo(() => countryList().getData(), [])
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [all_candidates, setall_candidates] = useState([]);
    const [displayedcandidates, setdisplayedcandidates] = useState([]);
    const [tempcandidate, settempcandidate] = useState('');


    const [hint, sethint] = useState(Array.isArray(user?.prefferredskills) ? user?.prefferredskills[0].value : '');
    const [country, setcountry] = useState('');
    const [city, setcity] = useState('');

    //filters
    const [commitmenttype, setcommitmenttype] = useState('');
    const [experiencelevel, setexperiencelevel] = useState('');
    const [minexperience, setminexperience] = useState(0);
    const [maxexperience, setmaxexperience] = useState(200);


    const [showjobtypefilter, setshowjobtypefilter] = useState(true);
    const [showexperiencefilter, setshowexperiencefilter] = useState(true);
    const [showshortlist, setshowshortlist] = useState(false);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Hire and Onboard' }))
        }
        fetchcandidates()
    }, []);

    const fetchcandidates = () => {
        setloading(true)
        axiosInstance.post(Fetchcandidatesurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                const gotten_data = response.data.data.candidates.filter((obj) => obj.profile)

                setdisplayedcandidates(gotten_data)
                setall_candidates(gotten_data)
            } else {
                seterror('No candidates yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }




    const commitmenttypes = [
        'Full-time', 'Part-time', 'Contract', "Internship"
    ]

    const handlesearch = () => {

    }

    //change expericen level
    const onchangeexperience = (iscurrent, experience) => {
        if (iscurrent) {
            setexperiencelevel('')
            setminexperience(0)
            setmaxexperience(100)
        } else {
            setexperiencelevel(experience.label)
            setminexperience(experience.min)
            setmaxexperience(experience.max)
        }

    }

    //on change commitment type
    const onchangecommitment = (iscurrent, type) => {
        if (iscurrent) {
            setcommitmenttype('')
        } else {
            setcommitmenttype(type)
        }
    }

    //save candidate
    const onsaveCandidate = (newcandidate, is_saved) => {
        let clonedcandidates = []
        if (saved_candidates) { clonedcandidates = [...saved_candidates] }

        const checkcandidate_index = clonedcandidates.find((obj) => obj.id === newcandidate.id)

        if (is_saved) {
            clonedcandidates.splice(checkcandidate_index, 1)
        } else {
            clonedcandidates.push(newcandidate)
        }

        dispatch(saveCandidates(clonedcandidates))
        setsuccess(is_saved > -1 ? 'Candidate removed' : 'Candidate saved successfully')
    }


    //go to candidate profile
    const gotocandidateprofile = (candidate_profile) => {
        openurl(`${site_baseURL}/candidate-profile/${candidate_profile.public_code}`)
    }



    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showshortlist && <Shortlistmodal ondismiss={() => setshowshortlist(false)} candidate={tempcandidate} />}
            <Stack flexDirection={"row"} alignSelf={"center"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"}>
                    <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>Hire and Onboard</Typography>
                </Stack>
                <Button onClick={() => navigate("/clientaccount/saved-candidates")} disabled={!saved_candidates?.length} sx={{ height: 30, borderRadius: 2, textTransform: "none" }} color="secondary" endIcon={<Bookmark />} variant="outlined">Saved Candidates</Button>
            </Stack>
            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                <Box mt={2} display={{ xs: "none", lg: "block" }} width={"30%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Filter</Typography>
                        <Button color="primary" variant="text" sx={{ fontSize: 13, fontFamily: 'boldfont' }}>Clear All</Button>
                    </Stack>
                    <hr />
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Job Type</Typography>
                        <IconButton onClick={() => setshowjobtypefilter(!showjobtypefilter)}>
                            {showjobtypefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showjobtypefilter &&
                        <Box>
                            {commitmenttypes.map((type, key) => {
                                const iscurrent = type === commitmenttype

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangecommitment(iscurrent, type)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{type}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }

                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Experience</Typography>
                        <IconButton onClick={() => setshowexperiencefilter(!showexperiencefilter)}>
                            {showexperiencefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showexperiencefilter &&
                        <Box>
                            {experiencelevels.map((experience, key) => {
                                const iscurrent = experience.label === experiencelevel

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangeexperience(iscurrent, experience)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{experience.label}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                </Box>
                <Box mt={2} width={{ xs: "100%", lg: "68%" }}  >
                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                        <Customsectiontextinput
                            width={{ xs: "38%", lg: "40%" }}
                            value={hint}
                            onChange={sethint}
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Customreactselectinput
                            onChange={(e) => setcountry(e.label)}
                            width={{ xs: "30%", lg: "20%" }}
                            options={countryoptions}
                        />
                        <Customsectiontextinput
                            placeholder={'city'}
                            width={{ xs: "30%", lg: "20%" }}
                            value={city}
                            onChange={setcity}
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOnOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button onClick={() => handlesearch()} sx={{ height: 40, display: { xs: "none", lg: "flex" } }} variant="contained">Search</Button>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        {displayedcandidates.length > 0 && !loading &&
                            <Stack flexDirection={"row"}>
                                <Typography fontSize={13} color={'dimtext'}>Showing </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{displayedcandidates.length} </Typography>
                                <Typography ml={1} fontSize={13}>Candidates </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{hint} </Typography>
                                <Typography ml={1} fontSize={13} >in </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{country || 'the platform'}</Typography>
                            </Stack>}
                        <DataObject sx={{ cursor: "pointer" }} titleAccess="Filter technology" color="primary" />
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                        {!loading && displayedcandidates.length < 1 && <Nodatacomponent text={'No candidates match the search criteria'} />}
                        {loading && Array.from({ length: 10 }, (_, index) => (
                            <Assessmentskeleton key={index} />
                        ))}
                        {!loading && displayedcandidates.map((candidate, key) => {
                            const is_saved = (saved_candidates?.findIndex((obj) => obj.id === candidate.id)) > -1
                            const candidate_profile = candidate.profile

                            const candidate_description = `Experienced ${candidate_profile?.specialization} developer. Skilled in ${candidate?.userskill.map((val) => val.skill_name).join(', ')}`;


                            return (
                                <Box position={"relative"} key={key} width={{ xs: "100%", lg: "30%" }} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <IconButton onClick={() => {
                                        setshowshortlist(true)
                                        settempcandidate(candidate)
                                    }} title="tap to shortlist" sx={{ position: "absolute", right: 0, top: 0 }}><BookmarkAdd /></IconButton>
                                    <Stack flexDirection={"row"}>
                                        <img src={candidate?.profile_picture_url || dummymanimage} className="candidate-card-image" />
                                        <Box ml={2}>
                                            <Typography fontSize={14} fontFamily={'boldfont'}>{candidate.name}</Typography>
                                            <Typography sx={{ ...muistyles.twolinetextlimit, WebkitLineClamp: 1 }} fontSize={13} fontFamily={'boldfont'} color={dimtextcolor}>({candidate_profile.specialization})</Typography>
                                        </Box>
                                    </Stack>
                                    <Tooltip title={candidate_description} placement="top">
                                        <Typography mt={1} mb={1} fontSize={13} sx={muistyles.twolinetextlimit}>
                                            {candidate_description}
                                        </Typography>
                                    </Tooltip>
                                    <Typography sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%'
                                    }} fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>{candidate.email}</Typography>
                                    <Typography fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>{returnperiod(parseInt(candidate_profile?.proffessional_experience || 1))} Experience</Typography>
                                    <Stack mt={1} justifyContent={"space-between"} flexDirection={'row'}>
                                        <Button onClick={() => onsaveCandidate(candidate, is_saved)} sx={{ height: 30, textTransform: "none" }} color={is_saved ? 'error' : "secondary"} variant="outlined">{is_saved ? 'Remove' : 'Save'}</Button>
                                        <Button sx={{ height: 30, textTransform: "none" }} color="secondary" onClick={() => gotocandidateprofile(candidate_profile)} variant="contained">View Profile</Button>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Discovercandidates