import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/user";
import { AddOutlined, BorderColor, BorderColorOutlined, Clear, ContentCopy, HomeOutlined, LanguageOutlined, LocalPhoneOutlined, LocationOnOutlined, School, WorkOutline } from "@mui/icons-material";
import { assessmentsresults, dummymanimage, schoolsAttended } from "../../Utils/dummy";
import { copyToClipboard, formatDateRange, Greetings, openurl, returnperiod, returnsurname } from "../../Utils/functions";
import githubIcon from './../../../assets/svgs/github.svg';
import googleIcon from './../../../assets/svgs/google.svg';
import linkedinIcon from './../../../assets/svgs/linkedin.svg';
import { isMobile } from "react-device-detect";
import CircularWithValueLabel from "../../Components/circularprogress";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Addeducationmodal } from "./Modals/addeducationdetails";
import { useNavigate } from "react-router-dom";
import { boxborder, primarycolor } from "../../Utils/colors";
import { Erroralert, Successalert } from "../../Components/popups";
import muistyles from "../../Utils/muistyles";
import Editprofilephoto from "../Client/Account/editprofilephoto";
import { Addskillsmodal } from "./Modals/addskillsmodal";
import useAuthAxios from "../../../hooks/useAuthAxios";
import { base_url, CandidateEducationurl, Deletecandidatesskillsurl } from "../../Utils/urls";
import { site_baseURL } from "../../Utils/defaults";
import Nodatacomponent from "../../Components/nodatacomponent";

const Candidatehome = () => {
    const navigate = useNavigate()
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()


    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [education_details, seteducation_details] = useState([]);

    //modals
    const [showaddeducation, setshowaddeducation] = useState(false);
    const [showaddskills, setshowaddskills] = useState(false);
    const [showeditimage, setshoweditimage] = useState(false);

    const profilelink = `${site_baseURL}/candidate-profile/${user?.public_code}`


    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Dashboard' }))
            fetcheducationdetails()
        }
    }, []);


    const candidate_skills = user?.candidate_skills?.map((skill) =>
    ({
        ...skill,
        label: skill.label || skill.skill_name,
        years: skill.years || skill.years_of_experience
    }))


    const fetcheducationdetails = () => {
        axiosInstance.get(CandidateEducationurl).then((response) => {
            if (response.data.success) {
                const gottendetails = response.data.data?.education_details
                dispatch(setUser({ ...user, education_details: gottendetails }))
            }
        })
    }

    //delete skills 
    const Deleteuserskills = (id) => {
        setloading(true)
        axiosInstance.post(Deletecandidatesskillsurl, {
            ids: [id]
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const newskills = user?.candidate_skills.filter((obj) => obj.id !== id)
                dispatch(setUser({ ...user, candidate_skills: newskills }))
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }


    const copyprofilelink = () => {
        copyToClipboard(profilelink)
        setsuccess('Profile Link Copied successfully')
    }


    const average_ratings = () => {
        const sum_of_years = candidate_skills.reduce((total, skill) => total + parseInt(skill.years_of_experience), 0);
        return (sum_of_years / (candidate_skills?.length || 1)).toFixed(1);
    }




    const svgsize = isMobile ? "15px" : '20px'

    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showaddeducation && <Addeducationmodal ondismiss={() => setshowaddeducation(false)} />}
            {showeditimage && <Editprofilephoto ondismiss={() => setshoweditimage(false)} />}
            {showaddskills && <Addskillsmodal ondismiss={() => setshowaddskills(false)} />}
            <Box padding={{ xs: 1, lg: 3 }} borderRadius={3} mb={2} sx={{ background: `linear-gradient(to right, ${primarycolor}, #57a2df)`, }}>
                <Box>
                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontSize={{ xs: 14, lg: 20 }} color={'white'} fontFamily={'boldfont'}>{Greetings()} {returnsurname(user?.name)}</Typography>
                        <Button onClick={() => copyprofilelink()} sx={muistyles?.whitebutton} startIcon={<ContentCopy />} variant="contained">Share Public Profile</Button>
                    </Stack>
                    <Typography fontSize={{ xs: 13, lg: 15 }} color={'white'}>Welcome to your candidate dashboard</Typography>
                    <Typography fontSize={{ xs: 13, lg: 15 }} onClick={() => navigate("/candidateaccount/recommended-assessments")} mt={2} sx={{ color: 'white', textDecorationLine: 'underline', cursor: 'pointer' }} >Browse Recommended Assessments</Typography>
                </Box>
            </Box>
            <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"} >
                <Box width={{ xs: "100%", lg: "30%" }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Box height={150} width={"100%"} padding={2} sx={{ background: "linear-gradient(to right, #f8e7e7, #dcdff9)" }}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <img src={user?.profile_picture_url || dummymanimage} className="homeprofileimage" />
                            <Box onClick={() => setshoweditimage(true)} width={25} height={25} borderRadius={1} display={"flex"} alignItems={'center'} sx={{ backgroundColor: "white" }} justifyContent={"center"}>
                                <IconButton size='small'><BorderColor fontSize="small" /></IconButton>
                            </Box>
                        </Stack>
                    </Box>
                    <Box padding={2}>
                        <Typography fontFamily={'boldfont'} fontSize={17}>{user?.name}</Typography>
                        <Typography fontSize={14}>{user?.email}</Typography>
                        <Box mt={2} mb={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <WorkOutline fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.specialization}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LocationOnOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.state}, {user?.country}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LanguageOutlined fontSize="small" color="gray" />
                                <Typography
                                    color={'gray'}
                                    fontSize={14}
                                    ml={1}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%'
                                    }}
                                >
                                    {user?.linkdin}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LocalPhoneOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.phone || '2547 22 222 222'}</Typography>
                            </Stack>
                        </Box>
                        <hr />
                        <Box mt={1} mb={2}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontSize={14} fontFamily={'boldfont'}>More Details</Typography>
                                <Button onClick={() => navigate("/candidate-onboarding-5")} variant="text" color="primary">Edit</Button>
                            </Stack>
                            <Box >
                                <Typography fontSize={13}>Current</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>Github</Typography>
                                <Typography mt={1} fontSize={13}>Years of Experience</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(user?.proffessional_experience)}</Typography>
                                <Typography mt={1} fontSize={13}>Freelance</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(user?.freelance_experience)}</Typography>
                                <Typography mt={1} fontSize={13}>English proficiency</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.english_level}</Typography>
                                <Typography mt={1} fontSize={13}>Commitment Type</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.commitment_type}</Typography>
                                <Typography mt={1} fontSize={13}>Expected Pay</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.monthly_rate} $/month</Typography>
                            </Box>
                        </Box>
                        <hr />
                        <Box mt={1} mb={2}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontSize={14} fontFamily={'boldfont'}>Social Accounts</Typography>
                                <Button onClick={() => navigate("/candidate-onboarding-8")} variant="text" color="primary">Edit</Button>
                            </Stack>
                            <Stack onClick={() => openurl(user?.linkdin)} mt={1} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                <img src={linkedinIcon} style={{ height: svgsize, width: svgsize }} />
                                <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Linkedin</Typography>
                            </Stack>
                            <Stack onClick={() => openurl(user?.github)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                <img src={githubIcon} style={{ height: svgsize, width: svgsize }} />
                                <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Github</Typography>
                            </Stack>
                            {user?.custom_link &&
                                <Stack onClick={() => openurl(user.custom_link)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                    <img src={googleIcon} style={{ height: svgsize, width: svgsize }} />
                                    <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Website</Typography>
                                </Stack>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mt={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "68%" }} >
                    <Box sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <CircularWithValueLabel value={68} />
                            <Box ml={2} width={{ xs: "60%", lg: "auto" }}>
                                <Typography fontFamily={'boldfont'} fontSize={13}>Complete Your Assessments</Typography>
                                <Typography fontSize={12}>
                                    Complete the assessments related to your skills profile to
                                    be active and discoverable to recruiters looking for you
                                </Typography>
                            </Box>
                            <Button onClick={() => navigate("/candidateaccount/candidateassessments")} sx={{ height: 30, fontSize: 13 }} startIcon={<BorderColorOutlined />} variant="outlined" color="secondary">Complete</Button>
                        </Stack>
                    </Box>
                    <Stack mt={2} mb={2} flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                        <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/receive.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Points Collected</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>245</Typography>
                            </Box>
                        </Stack>
                        <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/rated.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Ratings</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>{average_ratings()}</Typography>
                            </Box>
                        </Stack>
                        <Stack mb={{ xs: 2, lg: 0 }} width={{ xs: "100%", lg: "29%" }} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/completed.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Taken</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>158</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Typography mb={1} mt={1} fontFamily={'boldfont'}>Average Ratings</Typography>
                        <ResponsiveContainer width="95%" height={300}>
                            <BarChart
                                data={candidate_skills}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="skill_name" />
                                <YAxis />
                                {/* <Tooltip /> */}
                                <Bar dataKey="years_of_experience" radius={[10, 10, 10, 10]} barSize={50} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Box width={{ xs: "80%", lg: "auto" }}>
                                <Typography mb={1} mt={1} fontFamily={'boldfont'}>Verified Skills</Typography>
                                <Typography fontSize={13}>This will help us understand your strengths and match you with the right opportunities</Typography>
                            </Box>
                            <Button onClick={() => navigate("/candidate-onboarding-3")} sx={{ height: 30, fontSize: 13, whiteSpace: "nowrap" }} variant="outlined" color="secondary" startIcon={<AddOutlined />}>Add New</Button>
                        </Stack>
                        <Box display={"flex"} flexWrap={"wrap"} mt={1} mb={2}>
                            {candidate_skills?.map((skill, key) => {

                                return (
                                    <Stack key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Typography fontSize={14} mr={1}>{skill?.skill_name}</Typography>
                                        <IconButton onClick={() => Deleteuserskills(skill.id)}><Clear fontSize="small" /></IconButton>
                                    </Stack>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography mb={1} mt={1} fontFamily={'boldfont'}>Education</Typography>
                            <Button onClick={() => setshowaddeducation(true)} sx={{ height: 30, fontSize: 13 }} variant="outlined" color="secondary" startIcon={<AddOutlined />}>Add New</Button>
                        </Stack>
                        <Box mt={1}>
                            {user?.education_details?.map((school, key) => {
                                return (
                                    <Stack mb={2} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Box width={40} height={40} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                            <IconButton><School fontSize="medium" /></IconButton>
                                        </Box>
                                        <Box ml={1}>
                                            <Typography fontSize={14} fontFamily={"boldfont"}>{school.institution}</Typography>
                                            <Typography fontSize={13}>
                                                {school.level} {school.certification} - {formatDateRange(school.start_date, school.end_date)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                )
                            })}
                            {user?.education_details?.length < 1 && <Nodatacomponent height={100} text={"No education background"} />}

                        </Box>

                    </Box>
                </Box>
            </Stack>

        </Box>
    )
}

export default Candidatehome